<template>
  <NavBar :leftNav="[]" :rightNav="[]" />
  <div id="labsPage" class="flexTopCol">
    <div id="markdownInBox">
      <div class="flexLeftRow" style="transform: translateX(-10px)">
        <div
          v-for="option of ['Document Upload', 'Text Upload']"
          :key="option"
          @click="toggleOption(option)"
        >
          <NavButton
            :text="option"
            :isSelected="option == this.selectedOption"
            style="width: fit-content"
          />
        </div>
      </div>
      <div class="upload-form" v-if="this.selectedOption == 'Document Upload'">
        <h1>Upload PDF and Submit Text</h1>

        <!-- File Upload -->
        <div>
          <input
            type="file"
            id="fileInput"
            class="file-input"
            @change="handleFileUpload"
          />
          <label for="fileInput">
            <LightButton
              :text="!this.fileName ? 'Upload document' : this.fileName"
              :img="!this.fileName ? 'upload' : 'pdf'"
              style="width: 100%; height: 30px"
            />
          </label>
        </div>

        <!-- Text Input -->
        <div style="margin-top: 40px">
          <p for="text">Parameters (optional)</p>
          <textarea
            id="text"
            class="newInput loginInput"
            style="height: 50px; width: 100%"
            v-model="inputText"
            placeholder="parameter 1: description AND parameter 2: description"
            rows="5"
          ></textarea>
        </div>

        <button
          v-if="!uploadLoading"
          class="buttonBlue loginButton"
          :disabled="!file"
          @click="submitData"
        >
          Submit
        </button>
        <div
          v-if="uploadLoading"
          class="buttonBlue signupButtonLoading"
          style="width: 52px"
        >
          <div class="turningAnimation">
            <svg width="20" height="20" viewBox="0 0 97 97" fill="none">
              <path
                d="M7 48.5C7 25.5802 25.5802 7 48.5 7"
                stroke="white"
                stroke-width="10"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
      </div>

      <div class="upload-form" v-if="this.selectedOption == 'Text Upload'">
        <h1>Upload Text</h1>

        <!-- File Upload -->
        <div>
          <p for="text">Text upload</p>
          <textarea
            id="text"
            class="newInput loginInput"
            style="height: 50px; width: 100%"
            v-model="textDoc"
            placeholder="copy paste text into here"
            rows="5"
          ></textarea>
        </div>

        <!-- Text Input -->
        <div style="margin-top: 40px">
          <p for="text">Parameters (optional)</p>
          <textarea
            id="text"
            class="newInput loginInput"
            style="height: 50px; width: 100%"
            v-model="inputText"
            placeholder="parameter 1: description AND parameter 2: description"
            rows="5"
          ></textarea>
        </div>

        <button
          v-if="!uploadLoading"
          class="buttonBlue loginButton"
          :disabled="!textDoc"
          @click="submitText"
        >
          Submit
        </button>
        <div
          v-if="uploadLoading"
          class="buttonBlue signupButtonLoading"
          style="width: 52px"
        >
          <div class="turningAnimation">
            <svg width="20" height="20" viewBox="0 0 97 97" fill="none">
              <path
                d="M7 48.5C7 25.5802 25.5802 7 48.5 7"
                stroke="white"
                stroke-width="10"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>
    <div id="markdownInBox">
      <h2>Document output</h2>
      <div v-html="this.htmlContent"></div>
    </div>
  </div>
</template>

<script>
// import firebase from "firebase";
// import db from "@/main.js";
import NavBar from "@/components/utilities/NavBar.vue";
import LightButton from "@/components/buttons/LightButton.vue";
import NavButton from "@/components/buttons/NavButton.vue";
// import { ref } from "vue";
import axios from "axios";

export default {
  name: "Labs",
  components: {
    NavBar,
    LightButton,
    NavButton,
  },
  data() {
    return {
      file: null, // Stores the uploaded file
      inputText: "", // Stores the entered text
      htmlContent: "<p>No document output yet</p>",
      uploadLoading: false,
      selectedOption: "Document Upload",
      textDoc: "",
    };
  },
  methods: {
    toggleOption(option) {
      this.selectedOption = option;
      this.htmlContent = "<p>No document output yet</p>";
    },
    handleFileUpload(event) {
      const uploadedFile = event.target.files[0];
      this.fileName = uploadedFile ? uploadedFile.name : "";
      console.log(this.fileName);
      if (uploadedFile && uploadedFile.type === "application/pdf") {
        this.file = event.target.files[0];
      } else {
        alert("Please upload a valid PDF file.");
        this.file = null;
      }
    },
    parseThroughParameters(string, isDoc) {
      if (string == "" && isDoc) return "";
      else if (string == "") return [];

      let additional_fields = [];
      const params = string.split(" AND ");

      for (let param of params) {
        let split_param = param.split(":");
        additional_fields.push({
          "field_name": split_param[0].replace(/ /g, "_").toLowerCase(),
          "field_type": "str",
          "description": split_param[1],
        });
      }

      if (isDoc) {
        return {
          "additional_fields": additional_fields,
        };
      } else {
        return additional_fields;
      }
    },
    async submitText() {
      this.uploadLoading = true;

      let token =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoibW9vbmFyY2gtYmFja2VuZCIsInN1YnNjcmlwdGlvbl90aWVyIjoiYWRtaW4iLCJleHBpcnlfZGF0ZSI6IjIwMjUtMDQtMTFUMTk6Mzk6MzYuMDM1MzM5KzAwOjAwIiwiZXhwIjoxNzQ0NDAwMzc2fQ.I5jUwv9VraRqOPHUkIf1ev7E2XZyYm3rgZ0Y6aWvZw8";

      console.log(this.parseThroughParameters(this.inputText, false));
      const data = {
        "text": this.textDoc,
        "additional_fields": this.parseThroughParameters(this.inputText, false),
        // "additional_fields": [],
      };

      const formData = new FormData();
      // formData.append("text", this.textDoc);
      formData.append("request", JSON.stringify(data));

      try {
        // const response = await axios.post(options);
        const response = await axios.post(
          "https://moonarchio--moonarch-backend-tools.modal.run/extract-text",
          formData,
          {
            headers: {
              "Authorization": `Bearer ${token}`, // Optional authorization
              "Content-Type": "multipart/form-data", // Required for file uploads
            },
          }
        );
        // alert("success");
        console.log(response);
        this.htmlContent = window.marked.marked(response.data.data);
      } catch (error) {
        console.error(error);
        // alert("Failed to submit data.");
      }
      this.uploadLoading = false;
    },
    async submitData() {
      console.log(this.parseThroughParameters(this.inputText, true));
      this.uploadLoading = true;

      console.log(this.file);
      let token =
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoibW9vbmFyY2gtYmFja2VuZCIsInN1YnNjcmlwdGlvbl90aWVyIjoiYWRtaW4iLCJleHBpcnlfZGF0ZSI6IjIwMjUtMDQtMTFUMTk6Mzk6MzYuMDM1MzM5KzAwOjAwIiwiZXhwIjoxNzQ0NDAwMzc2fQ.I5jUwv9VraRqOPHUkIf1ev7E2XZyYm3rgZ0Y6aWvZw8";

      console.log(token);

      const data = this.parseThroughParameters(this.inputText, true);

      const formData = new FormData();
      formData.append("file", this.file);
      formData.append("request", data ? JSON.stringify(data) : data);

      try {
        // const response = await axios.post(options);
        const response = await axios.post(
          "https://moonarchio--moonarch-backend-tools.modal.run/extract-doc",
          formData,
          {
            headers: {
              "Authorization": `Bearer ${token}`, // Optional authorization
              "Content-Type": "multipart/form-data", // Required for file uploads
            },
          }
        );
        // alert("success");
        console.log(response);
        this.htmlContent = window.marked.marked(response.data.data);
      } catch (error) {
        console.error(error);
        // alert("Failed to submit data.");
      }
      this.uploadLoading = false;
    },
  },
  setup() {
    return {};
  },
};
</script>

<style>
#labsPage {
  width: 100vw;
  height: calc(100vh - 61px);
  overflow-y: scroll;
}

.upload-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px;
}

.file-input {
  display: none; /* Hide the actual input element */
}
</style>

<template>
  <div id="navbar" class="flexSpaceBetweenRow">
    <div class="flexLeftRow">
      <!-- <router-link
        :to="{
          name: 'Documentation',
          params: {
            doctype: 'documentation',
            doc: 'overview',
          },
        }"
      > -->
      <img
        height="20"
        src="@/assets/images/moonarch-logo.png"
        style="padding: 20px; cursor: pointer"
      />
      <!-- </router-link> -->
      <div v-for="nav of this.leftNav" :key="nav" @mouseover="preload(nav)">
        <router-link
          :to="{
            name: 'Documentation',
            params: {
              doctype: nav.toLowerCase().replace(/ /g, '-'),
              doc: 'overview',
            },
          }"
        >
          <NavButton
            :text="nav"
            :isSelected="nav.toLowerCase().replace(/ /g, '-') == this.selected"
            style="width: fit-content"
          />
        </router-link>
      </div>
    </div>
    <!-- <div class="flexRightRow" style="margin-right: 10px">
      <div v-for="nav of this.rightNav" :key="nav">
        <a :href="'http://app.' + this.hostname">
          <NavButton
            @click="openLink(linkedinUrl)"
            :text="nav"
            img="external_link"
            style="width: fit-content"
          />
        </a>
      </div>
    </div> -->
  </div>
</template>

<script>
// import firebase from "firebase";
// import db from "@/main.js";
// import { ref } from "vue";
import NavButton from "@/components/buttons/NavButton.vue";
// import LightButton from "@/components/buttons/LightButton.vue";

export default {
  name: "NavBar",
  components: {
    NavButton,
    // LightButton,
  },
  methods: {
    preload(nav) {
      this.$emit("preloadPage", nav);
    },
  },
  props: {
    leftNav: Array,
    rightNav: Array,
    selected: String,
  },
  setup() {
    return {};
  },
  data() {
    return {
      hostname:
        window.location.hostname == "site.local"
          ? window.location.hostname + ":8080/"
          : window.location.hostname,
    };
  },
};
</script>

<style>
#navbar {
  border-bottom: thin solid #dadada;
  width: 100%;
  height: 60px;
}
</style>

<template>
  <div :class="[this.fullWidth ? '' : '', 'wholeNavBtn']">
    <div :class="[this.fullWidth ? 'fullWidth' : '', 'buttonClear']">
      <Icon
        :img="img"
        :hexCode="this.isSelected ? '#721cff' : '#636363'"
        :size="s"
        stroke="2"
      />
      <div
        v-if="text"
        style="color: #636363"
        :class="{ textWithImg: this.img, moonBlue: this.isSelected }"
      >
        {{ text }}
      </div>
    </div>
    <!-- <div v-if="isSelected" class="bottomLine"></div> -->
    <!-- <div v-else class="emptyBottomLine"></div> -->
  </div>
</template>

<script>
import Icon from "../utilities/Icon.vue";

export default {
  name: "NavButton",
  props: {
    text: String,
    img: String,
    icon_size: String,
    isSelected: Boolean,
    fullWidth: Boolean,
  },
  components: {
    Icon,
  },
  data() {
    return {
      imgSrc: "@/assets/icons/" + this.img + ".vue",
      colorHex: this.isSelected ? "#721cff" : "#636363",
      s: this.icon_size ? this.icon_size : "18",
    };
  },
  methods: {},
  setup() {
    return {};
  },
};
</script>

<style scoped>
.buttonClear {
  border-radius: 7px;
  cursor: pointer;
  font-size: 14px;
  padding: 5px 7px;
  margin: 2px;
  min-height: 18px;
  min-width: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
  text-decoration: none !important;
}
.wholeNavBtn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.buttonClear:hover {
  outline: thin solid #dadada;
}
.textWithImg {
  margin-left: 10px;
}
.bottomLine {
  margin-top: 1px;
  height: 3px;
  width: calc(100% - 20px);
  background: #721cff;
  border-radius: 2px;
}
.emptyBottomLine {
  margin-top: 1px;
  height: 3px;
}
.moonBlue {
  color: #721cff !important;
}
.fullWidth {
  width: 100%;
  justify-content: flex-start !important;
}
</style>

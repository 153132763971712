<template>
  <NavBar :leftNav="[]" :rightNav="[]" :selected="'login'" />
  <form @submit.prevent="login" id="docAuthScreen" class="flexCenterCol">
    <div>Please enter your email before accessing Moonarch docs</div>
    <div class="flexLeftRow">
      <input
        class="newInput loginInput"
        style="width: 200px; margin-right: 10px"
        v-model="email"
        type="text"
        id="email"
        name="email"
      />
      <input
        v-if="!loginLoading"
        class="buttonBlue loginButton"
        type="submit"
        value="Access"
      />
      <div
        v-if="loginLoading"
        class="buttonBlue signupButtonLoading"
        style="width: 52px"
      >
        <div class="turningAnimation">
          <svg width="20" height="20" viewBox="0 0 97 97" fill="none">
            <path
              d="M7 48.5C7 25.5802 25.5802 7 48.5 7"
              stroke="white"
              stroke-width="10"
              stroke-linecap="round"
            />
          </svg>
        </div>
        <!-- <span style="margin-left: 10px">Checking access...</span> -->
      </div>
    </div>
    <div class="loginLabel" style="max-width: 294px; color: red">
      <svg
        v-if="this.errorMessage"
        style="margin-right: 7px"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="17"
        height="17"
        color="red"
        fill="none"
      >
        <circle
          cx="12"
          cy="12"
          r="10"
          stroke="currentColor"
          stroke-width="1.5"
        />
        <path
          d="M11.992 15H12.001"
          stroke="currentColor"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M12 12L12 8"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      {{ this.errorMessage }}
    </div>
  </form>
</template>

<script>
import NavBar from "@/components/utilities/NavBar.vue";
import {
  // onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
  // getAuth,
  browserLocalPersistence,
} from "@firebase/auth";
// import { db, auth } from "@/main.js";
import { auth } from "@/main.js";
// import { getDoc, doc } from "@firebase/firestore";
import logAction from "@/trackers.js";

// import firebase from "firebase";
// import db from "@/main.js";
// import { ref } from "vue";

export default {
  name: "DocAuth",
  components: {
    NavBar,
  },
  methods: {
    async login() {
      try {
        // const auth = getAuth();
        this.email = this.email.replace(" ", "");
        this.loginLoading = true;
        setPersistence(auth, browserLocalPersistence)
          .then(() => {
            signInWithEmailAndPassword(auth, this.email, this.email)
              .then(async () => {
                this.errorMessage = null;
                logAction(this.email, "accessed docs");
                this.loginLoading = false;

                logAction(navigator.userAgent, "accessed docs");

                this.$router.push({
                  name: "Documentation",
                  params: { doctype: "documentation", doc: "overview" },
                });

                // if (auth.currentUser) {
                //   try {
                //     const user_ref = doc(db, "users", auth.currentUser.uid);

                //     const docSnap = await getDoc(user_ref);

                //     if (docSnap.exists()) {
                //       this.userInfo = auth.currentUser;
                //       if (docSnap.data().LUNA_FOCUS) {
                //         this.isLuna = true;
                //         // this.$router.push({
                //         //   name: "Luna",
                //         //   params: {
                //         //     companyID: "",
                //         //   },
                //         // });
                //       }
                //     } else {
                //       console.error("Document does not exist");
                //       this.errorMessage = "Email or password is wrong";
                //       logAction(this.email, "failed doc access");
                //       this.loginLoading = false;
                //     }
                //   } catch (error) {
                //     console.error("Error fetching document:", error);
                //     this.errorMessage =
                //       "An error occurred while fetching user data";
                //     logAction(this.email, "failed doc access");
                //     this.loginLoading = false;
                //   }
                // } else {
                //   console.error("auth.currentUser is null");
                //   this.errorMessage = "User is not authenticated";
                //   this.loginLoading = false;
                // }
              })
              .catch((error) => {
                console.error("Sign-in error:", error);
                this.errorMessage = "Email is wrong";
                logAction(this.email, "failed doc access");
                this.loginLoading = false;
              });
          })
          .catch(() => {
            this.errorMessage = "Email is wrong";
            logAction(this.email, "failed doc access");
            this.loginLoading = false;
          });
      } catch (error) {
        this.errorMessage = "Email is wrong";
        logAction(this.email, "failed doc access");
        this.loginLoading = false;
      }
    },
  },
  setup() {
    return {};
  },
  data() {
    return {
      loginLoading: false,
    };
  },
};
</script>

<style>
#docAuthScreen {
  width: 100vw;
  height: calc(100vh - 61px);
}
</style>

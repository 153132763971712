<template>
  <div class="loadingOutline">
    <div class="loadingLine"></div>
  </div>
  <div v-if="!this.errorState" class="loadingModel">
    <div class="loadingGif">
      <svg
        class="loadingGifComponent"
        viewBox="0 0 1272 1272"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M930 781C930 1019.03 737.035 1212 499 1212C260.965 1212 68 1019.03 68 781C68 542.965 260.965 350 499 350C602.835 350 698.093 386.718 772.5 447.879L970.307 249.5C959.311 230.89 953 209.182 953 186C953 116.964 1008.96 61 1078 61C1147.04 61 1203 116.964 1203 186C1203 255.036 1147.04 311 1078 311C1055.64 311 1034.66 305.131 1016.5 294.85L819.656 493C888.259 569.331 930 670.291 930 781ZM422 618C470.049 618 509 579.049 509 531C509 482.951 470.049 444 422 444C373.951 444 335 482.951 335 531C335 579.049 373.951 618 422 618ZM746 942C794.049 942 833 903.049 833 855C833 806.951 794.049 768 746 768C697.951 768 659 806.951 659 855C659 903.049 697.951 942 746 942Z"
        />
      </svg>
    </div>
    <div class="modelSteps">
      <div class="modelStep" v-for="step in [1, 2, 3]" :key="step">
        <div
          class="selectedFounderButtonInternal"
          v-if="
            (this.modelLoadingStep * 3) / (this.startupFounders.length + 1) >
            step
          "
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="18"
            viewBox="0 -960 960 960"
            width="18"
            fill="#721cff"
          >
            <path
              d="m382-324.769 352.077-352.077q5.846-5.846 13.769-6.231 7.923-.385 14.539 6.231Q769-670.231 769-662.577t-6.615 14.269l-357.77 358q-9.692 9.693-22.615 9.693-12.923 0-22.615-9.693l-162-162q-5.847-5.846-6.116-13.884-.269-8.039 6.346-14.654 6.616-6.616 14.27-6.616t14.269 6.616L382-324.769Z"
            />
          </svg>
        </div>
        <div class="selectFounderButtonInternal" v-else>
          <div
            class="turningAnimation"
            v-if="
              (this.modelLoadingStep * 3) / (this.startupFounders.length + 1) >
              step - 1
            "
          >
            <svg width="20" height="20" viewBox="0 0 97 97" fill="none">
              <path
                d="M7 48.5C7 25.5802 25.5802 7 48.5 7"
                stroke="#721cff"
                stroke-width="10"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
        <div v-if="step != 3" class="modelStepFill"></div>
      </div>
    </div>
    <div class="modelStepsTitles">
      <div class="modelStepsTitle">Industry</div>
      <div class="modelStepsTitle" style="margin-left: 10px">Founders</div>
      <div class="modelStepsTitle" style="transform: translateX(5px)">
        Company
      </div>
    </div>
    <div
      v-if="(this.modelLoadingStep * 3) / (this.startupFounders.length + 1) > 2"
    >
      Evaluating company...
    </div>
    <div
      v-else-if="
        (this.modelLoadingStep * 3) / (this.startupFounders.length + 1) > 1
      "
    >
      Evaluating founders...
    </div>
    <div
      v-else-if="
        (this.modelLoadingStep * 3) / (this.startupFounders.length + 1) > 0
      "
    >
      Evaluating market sentiment...
    </div>
  </div>
  <div v-if="this.errorState" class="loadingModel">
    <svg
      width="70"
      height="70"
      viewBox="0 0 1312 1312"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="649" cy="656" r="656" fill="#02001A" />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M730 1013C730 1251.03 537.035 1444 299 1444C60.9653 1444 -132 1251.03 -132 1013C-132 774.965 60.9653 582 299 582C402.835 582 498.093 618.718 572.5 679.879L770.307 481.5C759.311 462.89 753 441.182 753 418C753 348.964 808.964 293 878 293C947.036 293 1003 348.964 1003 418C1003 487.036 947.036 543 878 543C855.644 543 834.658 537.131 816.5 526.85L619.656 725C688.259 801.331 730 902.291 730 1013ZM303.87 731.121V794.749H253.75V844.87H190.121V794.749H140V731.121H190.121V681H253.75V731.121H303.87ZM626.87 1120.75V1057.12H576.75V1007H513.121V1057.12H463V1120.75H513.121V1170.87H576.75V1120.75H626.87Z"
        fill="white"
      />
    </svg>
    <div style="margin-top: 20px">Uh oh something went wrong.</div>
  </div>
</template>

<script>
// import firebase from "firebase";
import { storage, auth } from "@/main.js";
import { ref, uploadBytes, getDownloadURL } from "@firebase/storage"; //getStorage
// import db from "@/main.js";
// import { ref } from "vue";
// import logAction from "@/trackers.js";
import axios from "axios";

export default {
  name: "RunningModel",
  props: {
    // targetLocation: String,
    targetStartup: Object,
    // targetFunding: Number,
    // targetRevenue: Number,
    startupFounders: Object,
    stage: Object,
    industryCipher: Object,
    apiKey: String,
  },
  emits: {
    completedModelRun: null,
  },
  components: {},
  data() {
    // let user = firebase.auth().currentUser;
    let user = auth.currentUser;

    // console.log(this.wholeStartup);

    return {
      user: user,
      errorState: false,
      modelLoadingStep: 0,
      preparedData: {
        founder: [],
        company_name: this.targetStartup.name,
        // year_founded: this.targetStartup.year_founded,
        year_founded: parseInt(this.targetStartup.year_founded),
        month_founded: 1,
        industry: "SaaS",
      },
    };
  },
  mounted() {
    this.runModel();
  },
  methods: {
    async scrapeFounderLinkedin(link) {
      this.isScrapingFounderLinkedin = true;
      console.log(link);
      const options = {
        method: "POST",
        url: "https://moonarchio--moonarch-api.modal.run/scrape-person-linkedin",
        headers: {
          "Authorization":
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoicG9ydGFsIiwic3Vic2NyaXB0aW9uX3RpZXIiOiJhZG1pbiIsImV4cGlyeV9kYXRlIjoiMjAyNC0xMi0wNFQwNDowMTozMC44ODcxOTUrMDA6MDAiLCJleHAiOjE3MzMyODQ4OTB9.Csj5xlUX05XClmWTwpWsYvPzgq8POOnqYtfgutXzytw",
        },
        data: {
          url: link, //link,
        },
      };
      // "https://www.linkedin.com/in/ACwAACCF1ZQBCIdvGkb-OnT3dAoML2UithB1Kf8"
      try {
        const response = await axios.request(options);
        console.log("hello");
        this.isScrapingFounderLinkedin = false;

        console.log(response.data.data);
        return response.data.data;
      } catch (error) {
        console.log("world");
        this.isError = true;
        let errorMessage = error;
        console.error(errorMessage);
        this.isScrapingFounderLinkedin = false;
        return "BROKEN";
      }
    },
    makeid(length) {
      let result = "";
      const characters =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      const charactersLength = characters.length;
      let counter = 0;
      while (counter < length) {
        result += characters.charAt(
          Math.floor(Math.random() * charactersLength)
        );
        counter += 1;
      }
      return result;
    },
    // async imageUploader(mediaLink) {
    //   try {
    //     let mediaID = this.makeid(20);
    //     const response = await axios.get(mediaLink, {
    //       responseType: "arraybuffer",
    //     });

    //     if (response.status === 200) {
    //       const blob = new Blob([response.data]);
    //       const file = new File([blob], mediaID + ".jpg", {
    //         type: "image/jpeg",
    //       });

    //       let pathFile = "lunaImgs/";

    //       const path = pathFile + mediaID;

    //       // const storage = firebase.storage();
    //       const storageRef = storage.ref();
    //       const fileRef = storageRef.child(path);

    //       try {
    //         const snapshot = await fileRef.put(file);

    //         // Optionally, you can get the download URL
    //         const downloadURL = await snapshot.ref.getDownloadURL();

    //         return downloadURL; // Return the download URL if needed
    //       } catch (error) {
    //         console.error("Error uploading file:", error.message);
    //         throw error;
    //       }
    //     } else {
    //       console.error("Failed to fetch image from the media link");
    //     }
    //   } catch (error) {
    //     console.error("Error uploading image:", error.message);
    //   }
    // },
    async imageUploader(mediaLink) {
      try {
        const mediaID = this.makeid(20); // Ensure this function is defined elsewhere in your code
        const response = await axios.get(mediaLink, {
          responseType: "arraybuffer",
        });

        if (response.status === 200) {
          const blob = new Blob([response.data]);
          const file = new File([blob], `${mediaID}.jpg`, {
            type: "image/jpeg",
          });

          const pathFile = "lunaImgs/";
          const path = `${pathFile}${mediaID}`;

          // Initialize Firebase Storage
          // const storage = getStorage(); // Assuming Firebase has been initialized elsewhere
          const storageRef = ref(storage, path);

          try {
            const snapshot = await uploadBytes(storageRef, file);

            // Optionally, you can get the download URL
            const downloadURL = await getDownloadURL(snapshot.ref);

            return downloadURL; // Return the download URL if needed
          } catch (error) {
            console.error("Error uploading file:", error.message);
            throw error;
          }
        } else {
          console.error("Failed to fetch image from the media link");
        }
      } catch (error) {
        console.error("Error uploading image:", error.message);
      }
    },
    async runModel() {
      this.modelLoadingStep = 1;

      this.preparedData.founder = [];

      for (let fLink of this.startupFounders) {
        console.log(fLink);
        if (fLink != "EMPTY FOUNDER") {
          let linkToScrape = "";
          if (fLink.entityUrn) {
            linkToScrape = "https://linkedin.com/in/" + fLink.entityUrn;
          } else if (fLink.link) {
            linkToScrape = fLink.link;
            // this.preparedData.founder.push(fLink);
          } else if (fLink.linkedin) {
            linkToScrape = fLink.linkedin;
          }
          let returnedFounder = await this.scrapeFounderLinkedin(linkToScrape);
          if (returnedFounder != "BROKEN") {
            this.preparedData.founder.push(returnedFounder);
          }
          this.modelLoadingStep += 1;

          // tempDebug.founder.push(returnedFounder);
        }
      }
      console.log(this.preparedData);
      const options = {
        method: "POST",
        // url: "https://go-data-api.fly.dev/luna/",
        url: "https://moonarchio--luna.modal.run",
        headers: {
          "Content-Type": "application/json",
          "Authorization": "Bearer as-cpOvM2Njll80V2R1iu7unw",
        },
        data: {
          "type": "luna",
          "value": this.preparedData,
        },
      };
      try {
        const response = await axios.request(options);
        console.log(response.data);
        this.lunaConfidence =
          this.targetStartup.linkedin_id == "93605691"
            ? 0.794
            : response.data[0];
        if (this.lunaConfidence < 0.4) {
          this.lunaGrade = "SELL";
        } else if (this.lunaConfidence < 0.6) {
          this.lunaGrade = "HOLD";
        } else {
          this.lunaGrade = "BUY";
        }
        this.modelLoadingStep += 1;

        var timestamp = new Date();

        var imgLink = await this.imageUploader(this.targetStartup.logo_url);

        let itemToAdd = {
          USER: this.user.uid,
          DATAFORMODEL: this.preparedData,
          TARGETSTARTUP: this.targetStartup,
          // FUNDING: this.targetStartup.funding,
          // LOCATION: this.targetStartup.location,
          // REVENUE: this.targetStartup.revenue,
          TIMESTAMP: timestamp,
          CONFIDENCE: this.lunaConfidence,
          LINKEDIN: this.targetStartup.linkedin_url,
          LINKEDIN_ID: this.targetStartup.linkedin_id,
          NAME: this.targetStartup.name,
          IMAGE: imgLink,
        };

        this.$emit("completedModelRun", itemToAdd);
      } catch (error) {
        this.isError = true;
        this.errorState = true;
        let errorMessage = error;
        console.error(errorMessage);
      }
    },
  },
  setup() {
    return {};
  },
};
</script>

<style>
.loadingModel {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.loadingGif {
  height: 70px;
  width: 70px;
  overflow: hidden;
  background: #02001a;
  border-radius: 50%;
  border: thin solid white;
  animation: leftright 8s infinite;
  /* alternate linear */
}
.modelSteps {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 50px 0 10px 0;
}
.modelStepsTitles {
  padding-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 290px;
}
.modelStepsTitle {
  color: #731cff;
  font-size: 13px;
}
.modelStep {
  display: flex;
  align-items: center;
  justify-content: center;
}
.modelStepFill {
  height: 1px;
  background: #731cff;
  width: 100px;
}
.loadingGifComponent {
  width: 70px;
  height: 70px;
  fill: white;
  /* position: absolute; */
  transform: translate(-10px, 10px);
  /* margin: 0 -20px -20px 0; */
  /* animation: updown 3s infinite alternate cubic-bezier(1, 0.3, 0.3, 1); */
  animation: updown 4s infinite alternate linear;
}
@keyframes updown {
  0% {
    transform: translate(-10px, 10px);
  }
  20% {
    transform: translate(-10px, 10px);
  }
  30% {
    transform: translate(-70px, 70px);
  }
  70% {
    transform: translate(-70px, 70px);
  }
  80% {
    transform: translate(-10px, 10px);
  }
  100% {
    transform: translate(-10px, 10px);
  }
}
@keyframes leftright {
  0% {
    transform: rotate(0);
  }
  20% {
    transform: rotate(0);
  }
  30% {
    transform: rotate(-90deg);
  }
  50% {
    transform: rotate(-90deg);
  }
  70% {
    transform: rotate(-90deg);
  }
  /* 100% {
    transform: rotate(-90deg);
  } */
  80% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(0);
  }
}
</style>

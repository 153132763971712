<template>
  <div v-if="!this.isMobileDevice()">
    <!-- <NavBar
      v-if="this.$route.name != 'Login'"
      :user="userInfo"
      :isAdmin="isAdmin"
      :isLuna="isLuna"
      @logout="logOut"
      @docAction="saveAction"
      @checkAdmin="checkAdmin"
    /> -->
    <!-- <div
      id="appBackground"
      class="appLoadedBackground"
      :class="this.$route.name != 'Luna' ? 'appOldNav' : ''"
    >
      <LoadingAnimation />
      <div style="color: white; font-size: 30px; margin-bottom: 15px">
        Error 404
      </div>
      <div style="color: white; font-size: 15px">Something went wrong</div>
    </div> -->
    <router-view></router-view>
    <!-- <div id="appBackground" class="appLoadingBackground">
      <div style="color: white; font-size: 30px; margin-bottom: 15px">
        Error 404
      </div>
      <div style="color: white; font-size: 15px">Something went wrong</div>
    </div> -->
  </div>
  <div v-else class="mobileErrorMessage">
    <img src="@/assets/images/moonarch-logo.png" height="30" />
    <div style="margin-top: 40px; max-width: 200px; text-align: center">
      We're sorry, moonarch.vc is not available on mobile.
    </div>
  </div>
</template>

<script>
// import firebase from "firebase";
import { onAuthStateChanged } from "@firebase/auth";
import { db, auth } from "@/main.js";
import { ref } from "vue";
import logAction from "@/trackers.js";
// import NavBar from "./components/utilities/NavBar.vue";
import { getDoc, doc } from "@firebase/firestore"; //getFirestore
// import LoadingAnimation from "./components/utilities/LoadingAnimation.vue";

export default {
  name: "App",
  components: {
    // NavBar,
    // LoadingAnimation,
  },
  data() {
    return {
      loggedIn: false,
      userInfo: null,
      isAdmin: false,
      isLuna: false,
    };
  },
  setup() {
    const dashboardRef = ref(null);

    return {
      dashboardRef,
    };
  },
  mounted() {
    onAuthStateChanged(auth, async (user) => {
      if (user) {
        logAction(navigator.userAgent, "logged in");

        const user_ref = doc(db, "users", user.uid);
        const user_doc = await getDoc(user_ref);

        if (user_doc.exists()) {
          this.userInfo = user;
          this.$router.push({
            name: "Luna",
            params: {
              companyID: "",
            },
          });
          if (user_doc.data().LUNA_FOCUS) {
            this.isLuna = true;
          }
        }
      } else {
        logAction(navigator.userAgent, "logged out");
      }
    });
  },
  methods: {
    logOut() {
      auth.signOut().then(() => {
        this.loggedIn = false;
      });
    },

    // MAIN
    // * FUNCTION check if user is an admin
    async checkAdmin(uid) {
      await db
        .collection("admins")
        .doc(uid)
        .get()
        .then((doc) => {
          if (doc.data()) {
            this.isAdmin = true;
          } else {
            this.isAdmin = false;
          }
        });
    },

    isMobileDevice() {
      const userAgent = navigator.userAgent || navigator.vendor || window.opera;
      // Regex to detect various mobile devices
      return /android|iPad|iPhone|iPod/.test(userAgent) && !window.MSStream;
    },
  },
};
</script>

<style>
@font-face {
  font-family: dmBold;
  src: url(/assets/DM_Sans/DMSans-Bold.ttf);
}
.mobileErrorMessage {
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
body {
  margin: 0;
  outline: 0;
  font-family: sans-serif;
}
h2 {
  font-weight: 500;
  margin: 5px;
  font-size: 20px;
}
a {
  text-decoration: none;
}
#appBackground {
  /* height: calc(100% - 50px); */
  height: 100%;
  /* top: 50px; */
  top: 0;
  width: 100%;
  left: 0;
  position: absolute;
  z-index: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 0;
  background: #f9f4ff;
  /* background: #02001a; */
}
.appLoadingBackground {
  background: #f9f4ff;
  /* background: #313044; */
}
.appLoadedBackground {
  background: #f9f4ff;
  /* background: #02001a; */
}
.appOldNav {
  height: calc(100% - 50px);
  top: 50px;
}
#mobileScreen {
  background: black;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.mobileText {
  color: white;
  font-family: dm;
  font-size: 16px;
  width: 130px;
  text-align: center;
  padding: 30px 0;
}
/* Scroll bar stylings */
::-webkit-scrollbar {
  background-color: transparent;
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #b7b7b74c;
  border-radius: 5px;
  /* border: thin solid #b7b7b7; */
  border: 2px solid white;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #b7b7b797;
}

/* FLEX */
.flexCenterCol {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.flexTopCol {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
}
.flexCenterRow {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.flexLeftRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}
.flexRightRow {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
}
.flexSpaceBetweenRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.flexWrap {
  flex-wrap: wrap;
}
.buttonLight-design {
  border-radius: 7px;
  border-bottom: thin solid #dadada;
  outline: thin solid #dadada;
  padding: 5px 7px;
  margin: 2px;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* BUTTONS */
.buttonLight {
  border-radius: 7px;
  cursor: pointer;
  border-bottom: thin solid #dadada;
  font-size: 14px;
  padding: 5px 7px;
  margin: 2px;
  outline: thin solid #dadada;
  display: flex;
  align-items: center;
  justify-content: center;
  background: white;
}
.buttonLight:hover {
  background: #efefef;
}
.buttonLight:active {
  border-bottom: none;
  margin-top: 3px;
}
.buttonClear {
  padding: 10px 20px;
  margin: 5px;
  height: 20px;
  font-size: 15px;
  cursor: pointer;
  border-radius: 7px;
  border: thin solid transparent;
  text-decoration: none;
  color: black;
}
.buttonClear:hover {
  /* background: #4b4b4b; */
  background: #9595951b;
  /* border: thin solid #9595953f; */
}
.buttonShadow {
  background: #9595951b;
}
.buttonLight2 {
  background: white;
  border: thin white solid;
  box-shadow: 0 0 0 1px #731cff;
  cursor: pointer;
  align-items: center;
  padding: 0px 20px;
  border-radius: 7px;
  font-family: sans-serif;
  font-size: 16px;
  color: black;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonLight2:hover {
  background: #cdaeff;
  border: thin solid #cdaeff;
}
.buttonBlue {
  /* margin: 0px 5px; */
  background: #731cff;
  border: thin #b78aff solid;
  box-shadow: 0 0 0 1px #731cff;
  cursor: pointer;
  align-items: center;
  padding: 0px 20px;
  border-radius: 7px;
  font-family: sans-serif;
  font-size: 16px;
  color: white;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.buttonBlue:hover {
  background: #9f63ff;
}
.tableBtn {
  padding: 5px 10px;
  margin: 1px 2px;
  height: 20px;
  display: flex;
  /* margin: 5px; */
  align-items: center;
  /* justify-content: center; */
  font-size: 13px;
  cursor: pointer;
  border-radius: 3px;
  border: thin solid transparent;
  background: #9595953f;
  border: thin solid #9595953f;
  color: #4d4d4d;
  width: fit-content;
}
.tableBtn:hover {
  border: solid thin #959595;
  /* border: thin solrgba(130, 30, 30, 0.247)53f; */
}
.newInput {
  padding: 8px;
  border-radius: 7px;
  height: 14px;
  font-size: 14px;
  border: thin solid #9595953f;
  color: black;
  outline: none;
  font-family: Arial, Helvetica, sans-serif;
}
/* {
  border: thin solid #731cff;
} */
.newInput:hover,
.newInput:focus,
.newInput:active {
  border: thin solid #731cff;
  /* outline: 3px solid #731cff73 !important; */
}
.newInput:target {
  border: thin solid #731cff;
  outline: 3px solid #731cff73 !important;
}
.unselectableInput {
  background: #9595953f;
}
.unselectableInput:hover,
.unselectableInput:focus,
.unselectableInput:active {
  border: thin solid #9595953f;
}
.newSelect {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: thin solid #9595953f;
  border-radius: 7px;
  outline: none;
  cursor: pointer;
  padding: 0 8px;
  height: 32px;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;
}
.newSelect:hover,
.newSelect:focus,
.newSelect:active {
  border: thin solid #731cff;
}
.highlightSelect {
  border: thin solid #731cff;
}
.targetSelect {
  border: thin solid #731cff !important;
  outline: 3px solid #731cff73 !important;
  transition: ease-in-out 0.1s;
}
.noneSelected {
  color: rgb(169, 169, 169);
}
.hoverMessage {
  /* position: static; */
  /* transform: translateY(calc(-100% - 25px)); */
  margin-top: -60px;
  width: fit-content;
  z-index: 200;
  background: rgba(0, 0, 0, 0.784);
  color: white;
  font-size: 12px;
  padding: 5px;
  border-radius: 5px;
  /* font-size: 20x; */
  display: none;
  font-weight: normal !important;
  position: absolute;
}
.buttonBlue:hover .hoverMessage {
  display: block;
  /* margin-bottom: 22px; */
  transform: translate(-56px, -10px);
  /* background: red; */
  /* z-index: 100 !important; */
}
.greyTitle {
  color: #0000008a;
  font-size: 12px;
  font-weight: 600;
}
.newGreyTitle {
  font-size: 12px;
  color: gray;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 0;
  font-weight: 500;
}
.boxShadow {
  border: thin #0000002e solid;
  box-shadow: 0 0px 8px 0 #0000001d;
}
.boxShadowLight {
  box-shadow: 0px 0px 2px #e0e0e0, 0px 1px 4px -2px #3d3d3d0f,
    0px 4px 4px -2px #3535351c;
}
.moonBlueHighlight {
  color: #721cff;
  background: #721cff11;
  border-color: #721cff;
}
.turningAnimation {
  display: flex;
  align-items: center;
  justify-content: center;
  animation: rotate 0.5s linear infinite;
}
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
#popupBackground {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: #0000001d;
}
#popupForeground {
  display: flex;
  background: white;
  position: fixed;
  margin: auto;
  width: 900px;
  left: calc(50% - 450px);
  right: calc(50% - 450px);
  top: 95px;
  bottom: 50px;
  border-radius: 7px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 4;
}
</style>

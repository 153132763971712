<template>
  <NavBar
    @click="changeDocType()"
    @preloadPage="preloadPage"
    :leftNav="['Documentation', 'Endpoints']"
    :rightNav="['Login to App']"
    :selected="this.currentType"
  />
  <div class="flexLeftRow" style="height: calc(100vh - 61px)">
    <!-- <div class=""></div> -->
    <VerticalMenu
      @click="checkPath()"
      :nav="this.listOfPaths"
      :selected="currentView"
    />
    <div id="markdownOutBox">
      <div id="markdownInBox" v-html="this.htmlContent"></div>
    </div>
  </div>
</template>

<script>
// import firebase from "firebase";
// import db from "@/main.js";
// import { ref } from "vue";
import axios from "axios";
import { useRoute } from "vue-router";
// import { marked } from "marked";
import NavBar from "@/components/utilities/NavBar.vue";
import VerticalMenu from "@/components/utilities/VerticalMenu.vue";

export default {
  name: "Documentation",
  components: {
    NavBar,
    VerticalMenu,
  },
  async mounted() {
    console.log("mounted");
    console.log(this.listOfPaths);

    await this.getPaths(
      "moonarchio",
      "documentation",
      "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",
      this.docType ? this.docType : "documentation"
    );
    console.log(this.listOfPaths);

    let index =
      this.listOfPaths
        .map((item) => item.toLowerCase())
        .indexOf(this.currentView) + 1;

    this.getDocumentPage(
      "moonarchio",
      "documentation",
      "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",
      this.currentType + "/" + index + "-" + this.currentView
    );
  },
  setup() {
    console.log("setup");
    const route = useRoute();
    const docType = route.params.doctype;
    const docPath = route.params.doc;
    // console.log(docPath);
    return { docType, docPath, route };
  },
  data() {
    console.log("data");
    // console.log(window.marked);
    this.fetchMarkdown();
    this.getPaths(
      "moonarchio",
      "documentation",
      "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",
      this.docType ? this.docType : "documentation"
    );

    return {
      markdownUrl:
        "https://raw.githubusercontent.com/moonarchio/documentation/refs/heads/main/documentation/Overview?token=GHSAT0AAAAAACZGB3YMKUBSZZADYUQSDTH2Z2LNOZA",
      htmlContent: window.marked.marked(
        "We're sorry, this page does not exist"
      ),
      loading: true,
      error: null,
      // .replace(/-/g, " ")
      // .split(" ")
      // .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      // .join(" ")
      currentType: this.docType ? this.docType : "documentation",
      currentView: this.docPath ? this.docPath : "overview",
      listOfPathsIsLoaded: false,
      listOfPaths: [],
    };
  },
  methods: {
    // preloadPage(nav) {
    //   console.log(nav);
    // },
    changeDocType() {
      this.checkPath();
      this.getPaths(
        "moonarchio",
        "documentation",
        "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",
        this.currentType ? this.currentType : "documentation"
      );
    },
    checkPath() {
      this.currentType = this.$route.params.doctype;
      this.currentView = this.$route.params.doc;

      let index =
        this.listOfPaths
          .map((item) => item.toLowerCase())
          .indexOf(this.currentView) + 1;
      this.getDocumentPage(
        "moonarchio",
        "documentation",
        "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",
        this.currentType + "/" + index + "-" + this.currentView
      );
    },
    async getDocumentPage(owner, repo, token, path) {
      this.htmlContent = window.marked.marked("# " + this.currentView);
      // console.log("getting doc");
      const GITHUB_API_URL = "https://api.github.com";
      const url = `${GITHUB_API_URL}/repos/${owner}/${repo}/contents/${path}`;
      console.log(url);

      const headers = token ? { Authorization: `Bearer ${token}` } : {};

      try {
        const response = await fetch(url, { headers });

        if (!response.ok) {
          const errorData = await response.json();
          throw new Error(`Error ${response.status}: ${errorData.message}`);
        }
        console.log(response);
        const data = await response.json();
        console.log("hello world");
        if (data.encoding === "base64") {
          // Decode Base64 content
          const markdownContent = Buffer.from(data.content, "base64").toString(
            "utf-8"
          );

          this.htmlContent = window.marked.marked(markdownContent);
        } else {
          throw new Error("Unsupported encoding: " + data.encoding);
        }
      } catch (error) {
        console.error("Failed to fetch Markdown file:", error);
        return null;
      }
    },
    async getPaths(owner, repo, token, path = "") {
      const GITHUB_API_URL = "https://api.github.com";
      const url = `${GITHUB_API_URL}/repos/${owner}/${repo}/contents/${path}`;

      const headers = {
        Authorization: `Bearer ${token}`,
      };

      try {
        const response = await fetch(url, { headers });

        if (!response.ok) {
          const errorData = await response.json();
          console.error(`Error: ${response.status}, ${errorData.message}`);
          return [];
        }

        const items = await response.json();
        const files = [];

        for (const item of items) {
          // console.log(item);
          if (item.type === "file") {
            files.push(item.name);
          }
        }

        console.log(
          files
            .sort((a, b) => {
              const numA = parseInt(a.split("-")[0], 10);
              const numB = parseInt(b.split("-")[0], 10);
              return numA - numB;
            })
            .map((item) => item.split("-")[1])
        );
        this.listOfPathsIsLoaded = true;
        this.listOfPaths = files
          .sort((a, b) => {
            // Extract numbers before the '-' and compare them
            const numA = parseInt(a.split("-")[0], 10);
            const numB = parseInt(b.split("-")[0], 10);
            return numA - numB;
          })
          .map((item) => {
            // Extract the word after '-' and capitalize the first letter
            const word = item.split("-")[1];
            if (!word.includes("_")) {
              return word.charAt(0).toUpperCase() + word.slice(1);
            } else {
              return word;
            }
          });
      } catch (error) {
        console.error("Error fetching files:", error);
        return [];
      }
    },
    async fetchFiles() {
      // console.log(marked);
      try {
        // console.log(marked);
        const response = await axios.get(
          "https://github.com/moonarchio/documentation/tree/55410ff272122df21c208a7bcf46b62ad2922f50/documentation"
        );
        console.log(response.data);
        // const markdown = response.data;

        // this.htmlContent = window.marked.marked(markdown);
      } catch (err) {
        this.error = "Error loading Markdown file.";
        console.error(err);
      } finally {
        this.loading = false;
      }
      // return;
    },
    async fetchMarkdown() {
      // console.log(marked);
      try {
        // console.log(marked);
        const response = await axios.get(
          "https://raw.githubusercontent.com/moonarchio/documentation/refs/heads/main/documentation/Overview?token=GHSAT0AAAAAACZGB3YMVBJFM3TGQLE4GZZMZ2LNUXA"
        );
        const markdown = response.data;

        this.htmlContent = window.marked.marked(markdown);
      } catch (err) {
        this.error = "Error loading Markdown file.";
        console.error(err);
      } finally {
        this.loading = false;
      }
      // return;
    },
  },
};
</script>

<style>
#markdownOutBox {
  width: calc(100vw - 251px);
  height: calc(100vh - 61px);
  display: flex;
  justify-content: center;
  overflow-y: scroll;
}
#markdownInBox {
  width: 600px;
  margin: 40px;
  /* margin-bottom: 80px; */
}
#markdownInBox h1 {
  font-weight: normal;
  font-size: 20px;
  margin-bottom: 40px;
}
#markdownInBox h2 {
  font-weight: normal;
  font-size: 18px;
  margin-bottom: 30px;
  margin-left: 0;
}
#markdownInBox h3 {
  font-weight: normal;
  font-size: 16px;
  /* margin-bottom: 30px; */
}
#markdownInBox p {
  color: #636363;
  font-size: 15px;
  /* margin-bottom: 30px; */
}
#markdownInBox li {
  color: #636363;
  font-size: 15px;
  margin-bottom: 30px;
  list-style-type: none;
}
#markdownInBox hr {
  color: #dedede35;
  margin-bottom: 30px;
  margin-top: 30px;
}
#markdownInBox a {
  color: #721cff;
}
#markdownInBox pre {
  border: thin solid #dedede;
  background: #dedede35;
  border-radius: 5px;
  padding: 10px;
  overflow-x: scroll;
}
#markdownInBox code {
  overflow-x: hidden;
}
#markdownInBox strong {
  border: thin solid #dedede;
  /* background: #dedede35; */
  background: #731cff13;
  border-radius: 5px;
  padding: 1px 5px;
  color: black;
  font-weight: normal;
  font-size: 14px;
}
#markdownInBox summary {
  cursor: pointer;
  margin-bottom: 10px;
  /* border-left: 0px solid red; */
  /* background: white; */
  /* border-radius: 5px; */
}
#markdownInBox details {
  padding: 5px;
  /* border-bottom: thin solid white; */
  margin-top: 5px;
}
/* #markdownInBox details[open] {
  background: #dedede18;
  border-bottom: thin solid #dedede;
  border-radius: 5px;
}
#markdownInBox details p {
  padding: 0 10px;
  margin: 0;
} */
#markdownInBox details li {
  /* padding: 0 10px; */
  margin: 0;
  padding-bottom: 5px;
}
#markdownInBox table {
  min-width: 600px;
}
#markdownInBox thead {
  margin: 0;
  padding: 0;
  border-spacing: 0px;
}
#markdownInBox tr {
  margin: 0;
}
#markdownInBox th {
  padding: 10px 5px;
  border-top: thin solid #dedede;
  background: #dededeb1;
  font-weight: normal;
}
#markdownInBox td {
  padding: 10px 0;
  border-top: thin solid #dedede;
  color: #636363;
  font-size: 15px;
  line-height: 1;
}
</style>

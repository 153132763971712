<template>
  <div id="verticalmenu">
    <div v-for="item of nav" :key="item" style="width: 100%">
      <router-link
        :to="{
          name: 'Documentation',
          params: {
            doctype: this.$route.params.doctype,
            doc: item.toLowerCase().replace(/ /g, '-'),
          },
        }"
      >
        <NavButton
          :text="item"
          :isSelected="item.toLowerCase().replace(/ /g, '-') == this.selected"
          :fullWidth="true"
        />
      </router-link>
      <a :href="item.toLowerCase().replace(/ /g, '-')"> </a>
    </div>
  </div>
</template>

<script>
// import firebase from "firebase";
// import db from "@/main.js";
// import { ref } from "vue";
import NavButton from "@/components/buttons/NavButton.vue";
// import LightButton from "@/components/buttons/LightButton.vue";

export default {
  name: "VerticalMenu",
  components: {
    NavButton,
    // LightButton,
  },
  methods: {},
  props: {
    nav: Array,
    selected: String,
  },
  setup() {
    return {};
  },
  data() {
    return {};
  },
};
</script>

<style>
#verticalmenu {
  border-right: thin solid #dadada;
  width: 190px;
  height: calc(100% - 40px);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px 30px;
}
</style>

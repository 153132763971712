<template>
  <div v-if="!this.foundersHaveLoaded" class="loadingOutline">
    <div class="loadingLine"></div>
  </div>
  <!-- <div v-if="this.fakeLoading" class="fakeLoadingBox">
    <svg
      v-if="this.lunaStage != 0"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      width="35"
      height="35"
      color="black"
      class="circularLoading"
      fill="none"
    >
      <path
        d="M17.5 17.5L22 22"
        stroke="currentColor"
        stroke-width="3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M20 11C20 6.02944 15.9706 2 11 2C6.02944 2 2 6.02944 2 11C2 15.9706 6.02944 20 11 20C15.9706 20 20 15.9706 20 11Z"
        stroke="currentColor"
        stroke-width="3"
        stroke-linejoin="round"
      />
    </svg>
    <div class="modelSteps">
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="50"
          height="50"
          color="#721cff"
          fill="none"
          stroke-width="1.25"
        >
          <path d="M12 11L12 20" stroke="currentColor" stroke-linecap="round" />
          <path
            d="M14 9C14 10.1046 13.1046 11 12 11C10.8954 11 10 10.1046 10 9C10 7.89543 10.8954 7 12 7C13.1046 7 14 7.89543 14 9Z"
            stroke="currentColor"
          />
          <path
            d="M16.9588 6C17.6186 6.86961 18 7.89801 18 9C18 10.102 17.6186 11.1304 16.9588 12M7.04117 6C6.38143 6.86961 6 7.89801 6 9C6 10.102 6.38143 11.1304 7.04117 12"
            stroke="currentColor"
            stroke-width="1"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M20.3159 4C21.3796 5.43008 22 7.14984 22 9C22 10.8502 21.3796 12.5699 20.3159 14M3.68409 4C2.62036 5.43008 2 7.14984 2 9C2 10.8502 2.62036 12.5699 3.68409 14"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div style="width: 125px"></div>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="50"
          height="50"
          color="#721cff"
          fill="none"
          stroke-width="1.25"
        >
          <path
            d="M16 10L18.1494 10.6448C19.5226 11.0568 20.2092 11.2628 20.6046 11.7942C21 12.3256 21 13.0425 21 14.4761V22"
            stroke="currentColor"
            stroke-linejoin="round"
          />
          <path
            d="M8 9L11 9M8 13L11 13"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M12 22V19C12 18.0572 12 17.5858 11.7071 17.2929C11.4142 17 10.9428 17 10 17H9C8.05719 17 7.58579 17 7.29289 17.2929C7 17.5858 7 18.0572 7 19V22"
            stroke="currentColor"
            stroke-linejoin="round"
          />
          <path d="M2 22L22 22" stroke="currentColor" stroke-linecap="round" />
          <path
            d="M3 22V6.71724C3 4.20649 3 2.95111 3.79118 2.32824C4.58237 1.70537 5.74742 2.04355 8.07752 2.7199L13.0775 4.17122C14.4836 4.57937 15.1867 4.78344 15.5933 5.33965C16 5.89587 16 6.65344 16 8.16857V22"
            stroke="currentColor"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <div style="width: 125px"></div>
      <div>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width="50"
          height="50"
          color="#721cff"
          fill="none"
          stroke-width="1.25"
        >
          <path
            d="M18.6161 20H19.1063C20.2561 20 21.1707 19.4761 21.9919 18.7436C24.078 16.8826 19.1741 15 17.5 15M15.5 5.06877C15.7271 5.02373 15.9629 5 16.2048 5C18.0247 5 19.5 6.34315 19.5 8C19.5 9.65685 18.0247 11 16.2048 11C15.9629 11 15.7271 10.9763 15.5 10.9312"
            stroke="currentColor"
            stroke-linecap="round"
          />
          <path
            d="M4.48131 16.1112C3.30234 16.743 0.211137 18.0331 2.09388 19.6474C3.01359 20.436 4.03791 21 5.32572 21H12.6743C13.9621 21 14.9864 20.436 15.9061 19.6474C17.7889 18.0331 14.6977 16.743 13.5187 16.1112C10.754 14.6296 7.24599 14.6296 4.48131 16.1112Z"
            stroke="currentColor"
          />
          <path
            d="M13 7.5C13 9.70914 11.2091 11.5 9 11.5C6.79086 11.5 5 9.70914 5 7.5C5 5.29086 6.79086 3.5 9 3.5C11.2091 3.5 13 5.29086 13 7.5Z"
            stroke="currentColor"
          />
        </svg>
      </div>
    </div>
    <div class="modelSteps" style="padding-top: 0">
      <div class="modelStep" v-for="step in [1, 2, 3]" :key="step">
        <div class="selectedFounderButtonInternal" v-if="this.fakeStep > step">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="18"
            viewBox="0 -960 960 960"
            width="18"
            fill="#721cff"
          >
            <path
              d="m382-324.769 352.077-352.077q5.846-5.846 13.769-6.231 7.923-.385 14.539 6.231Q769-670.231 769-662.577t-6.615 14.269l-357.77 358q-9.692 9.693-22.615 9.693-12.923 0-22.615-9.693l-162-162q-5.847-5.846-6.116-13.884-.269-8.039 6.346-14.654 6.616-6.616 14.27-6.616t14.269 6.616L382-324.769Z"
            />
          </svg>
        </div>
        <div class="selectFounderButtonInternal" v-else>
          <div class="turningAnimation" v-if="this.fakeStep == step">
            <svg width="20" height="20" viewBox="0 0 97 97" fill="none">
              <path
                d="M7 48.5C7 25.5802 25.5802 7 48.5 7"
                stroke="#721cff"
                stroke-width="10"
                stroke-linecap="round"
              />
            </svg>
          </div>
        </div>
        <div v-if="step != 3" class="modelStepFill" style="width: 150px"></div>
      </div>
    </div>
    <div style="margin-top: 15px; font-size: 20px">
      Live scraping {{ this.fakeStepItem }} data for
      {{ this.targetStartup.name }}
    </div>
  </div> -->
  <div class="lunaMainResults" style="width: 872px">
    <div class="">
      <div class="flexLeftRow lunaHeaderItem">
        <div class="tableBtn" style="margin: 0" @click="goBack()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="20"
            viewBox="0 -960 960 960"
            width="20"
          >
            <path
              d="m294.922-450.001 206.77 206.77q8.923 8.923 8.807 20.884-.115 11.962-9.423 21.269-9.307 8.692-21.076 9-11.769.308-21.076-9L205.309-454.693q-5.615-5.615-7.923-11.846-2.308-6.23-2.308-13.461t2.308-13.461q2.308-6.231 7.923-11.846l253.615-253.615q8.307-8.308 20.576-8.5 12.269-.192 21.576 8.5 9.308 9.307 9.308 21.384t-9.308 21.384L294.922-509.999h455.077q12.769 0 21.384 8.615 8.616 8.615 8.616 21.384t-8.616 21.384q-8.615 8.615-21.384 8.615H294.922Z"
            />
          </svg>
        </div>
        <div class="flexLeftRow" style="margin-left: 5px">
          <div class="roundLogo" style="border-radius: 5px">
            <img :src="this.targetStartup.logo_url" height="30" width="30" />
          </div>
          <span class="resultFont" style="margin-left: 10px">{{
            this.targetStartup.name
          }}</span>
        </div>
      </div>
      <div class="flexLeftRow" style="align-items: flex-start">
        <div style="margin-right: 20px">
          <div class="greyTitle" style="margin: 0; margin-bottom: 5px">
            links
          </div>
          <div class="flexLeftRow lunaHeaderItem">
            <div
              class="hoverLink"
              @click="openLink(this.targetStartup.website)"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="16"
                viewBox="0 -960 960 960"
                width="16"
                fill="#721cff"
              >
                <path
                  d="M480-55q-89 0-166.5-33t-135-91Q121-237 88-315T55-482q0-89 33-166.5T178.5-783q57.5-57 135-90T480-906q89 0 166.5 33t135 90q57.5 57 91 134.5T906-482q0 89-33.5 167t-91 136q-57.5 58-135 91T480-55Zm-1-91q32-35 55-80.5T573-336H388q13 60 36 107t55 83Zm-82-12q-26-36-42.5-80T326-336H180q36 70 85 109.5T397-158Zm165-1q70-22 126.5-67.5T779-336H635q-13 53-30 97t-43 80ZM161-393h154q-3-27-3.5-48t-.5-41q0-25 1-43.5t4-42.5H161q-7 24-9.5 42t-2.5 44q0 25 2.5 45t9.5 44Zm216 0h207q3-31 3.5-50t.5-39q0-20-.5-37.5T584-568H377q-4 31-5 48.5t-1 37.5q0 20 1 39t5 50Zm267 0h154q6-24 9-44t3-45q0-26-3-44t-9-42H645q1 36 2 53t1 33q0 21-1.5 40t-2.5 49Zm-10-233h145q-32-67-88.5-112.5T561-802q26 36 43 78.5t30 97.5Zm-246 0h186q-10-51-36-100.5T479-812q-29 26-50.5 69T388-626Zm-208 0h147q11-52 26.5-94t42.5-81q-74 18-128.5 62T180-626Z"
                />
              </svg>
            </div>
            <div
              class="hoverLink"
              @click="openLink(this.targetStartup.linkedin_url)"
            >
              <svg
                width="16"
                height="16"
                viewBox="0 0 800 800"
                fill="#721cff"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M100 0C44.7715 0 0 44.7715 0 100V700C0 755.228 44.7715 800 100 800H700C755.228 800 800 755.228 800 700V100C800 44.7715 755.228 0 700 0H100ZM248.83 664.619H130.138V307.527H248.83V664.619ZM189.484 258.789H188.7C148.884 258.789 123.077 231.358 123.077 197.073C123.077 162.034 149.639 135.381 190.261 135.381C230.892 135.381 255.861 162.034 256.638 197.073C256.638 231.358 230.892 258.789 189.484 258.789ZM676.923 664.619H558.2V473.574C558.2 425.589 541.046 392.835 498.077 392.835C465.261 392.835 445.76 414.889 437.169 436.251C434.047 443.882 433.231 454.49 433.231 465.19V664.621H314.484C314.484 664.621 316.076 341.029 314.484 307.529H433.231V358.129C449.007 333.853 477.162 299.146 540.23 299.146C618.361 299.146 676.923 350.17 676.923 459.845V664.619Z"
                />
              </svg>
            </div>
          </div>
        </div>
        <div v-if="this.targetStartup.tagline">
          <div class="greyTitle" style="margin: 0; margin-bottom: 5px">
            1-liner
          </div>
          <div class="flexLeftRow lunaHeaderItem">
            <div>{{ this.targetStartup.tagline }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="lunaMainResults" style="flex-direction: column; width: 872px">
    <div
      class="greyTitle blockTitle"
      style="display: flex; align-items: center; width: 860px"
    >
      <!-- <svg
        xmlns="http://www.w3.org/2000/svg"
        height="24"
        viewBox="0 -960 960 960"
        width="24"
        fill="grey"
      >
        <path
          d="M146.218-229.26q-32.507 0-55.863-23.522t-23.356-56.262v-341.912q0-31.74 23.356-55.762 23.356-24.022 55.863-24.022h667.564q32.507 0 55.863 24.022t23.356 55.762v341.912q0 32.74-23.356 56.262t-55.863 23.522H146.218Zm0-79.784h667.564v-341.912H690V-510q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T630-510v-140.956H510V-510q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T450-510v-140.956H330V-510q0 12.75-8.675 21.375-8.676 8.625-21.5 8.625-12.825 0-21.325-8.625T270-510v-140.956H146.218v341.912ZM300-480Zm180 0Zm180 0Zm-180 0Z"
        />
      </svg> -->
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
        width="24"
        height="24"
        color="grey"
        fill="none"
      >
        <path
          d="M5 9.97873C5 11.095 6.79086 12 9 12V9.97873C9 8.98454 9 8.48745 8.60252 8.18419C8.20504 7.88092 7.811 7.99435 7.02292 8.22121C5.81469 8.56902 5 9.2258 5 9.97873Z"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linejoin="round"
        />
        <path
          d="M16 8.5C16 10.433 12.866 12 9 12C5.13401 12 2 10.433 2 8.5C2 6.567 5.13401 5 9 5C12.866 5 16 6.567 16 8.5Z"
          stroke="currentColor"
          stroke-width="1.5"
        />
        <path
          d="M2 9V15.6667C2 17.5076 5.13401 19 9 19H20C20.9428 19 21.4142 19 21.7071 18.7071C22 18.4142 22 17.9428 22 17V14C22 13.0572 22 12.5858 21.7071 12.2929C21.4142 12 20.9428 12 20 12H9"
          stroke="currentColor"
          stroke-width="1.5"
        />
        <path
          d="M18 19V17M14 19V17M10 19V17M6 18.5V16.5"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <span style="margin-left: 10px">Metrics</span>
    </div>
    <div class="flexLeftRow" style="margin-top: 10px; flex-wrap: wrap">
      <div style="margin: 0 5px 20px 0">
        <div class="greyTitle" style="margin: 0 0 5px 0">Last Raised Round</div>
        <!-- <input
          v-if="this.targetStartup.stage && !this.canEditStage"
          class="newInput unselectableInput"
          v-model="this.targetStage"
          style="width: 200px"
          placeholder="select company stage"
          readonly
        /> -->
        <!-- v-model="this.stage[this.targetStartup.stage]" -->
        <!-- :class="!this.targetStartup.stage ? 'noneSelected' : ''" -->
        <select
          class="newSelect"
          :class="{
            // highlightSelect: this.targetStage == null,
            targetSelect: this.warnings.stage && this.targetStage == null,
          }"
          style="width: 285px"
          v-model="this.targetStage"
        >
          <option :value="null" disabled selected>select company round</option>
          <option
            v-for="stage in this.listOfStages"
            :key="stage"
            :value="stage"
          >
            {{ stage }}
          </option>
          <option
            v-if="
              this.startingStage &&
              !this.listOfStages.includes(this.startingStage)
            "
            disabled
          >
            unsupported company stage
          </option>
          <option
            v-if="
              this.startingStage &&
              !this.listOfStages.includes(this.startingStage)
            "
          >
            {{ this.startingStage }}
          </option>
        </select>
        <!-- {{ this.targetStartup.stage }} -->
      </div>
      <div style="margin: 0 5px 20px 0">
        <div class="greyTitle" style="margin: 0 0 5px 0">Total Funding ($)</div>
        <input
          class="newInput"
          style="width: 267px"
          v-model="this.editFunding"
          @input="formatFundingInput"
          placeholder="add total funding"
        />
      </div>
      <!-- <div style="margin: 0 5px 20px 0">
        <div class="greyTitle" style="margin: 0 0 5px 0">Revenue ($)</div>
        <input
          class="newInput"
          style="width: 267px"
          v-model="this.editRevenue"
          @input="formatRevenueInput"
          placeholder="add revenue"
        />
      </div> -->
      <div style="margin: 0 5px 20px 0">
        <div class="greyTitle" style="margin: 0 0 5px 0">Year Founded</div>
        <!-- <input
          v-if="this.targetStartup.year_founded"
          class="newInput unselectableInput"
          style="width: 200px"
          v-model="this.targetYear"
          placeholder="add year founded"
          readonly
        /> -->
        <input
          class="newInput"
          :class="{
            // highlightSelect: this.targetStage == null,
            targetSelect: this.warnings.year && this.targetYear == null,
          }"
          style="width: 267px"
          v-model="this.targetYear"
          type="number"
          oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0, 4);"
          placeholder="add year founded"
        />
      </div>
      <div style="margin: 0 5px 20px 0">
        <div class="greyTitle" style="margin: 0 0 5px 0">Industry</div>
        <!-- <input
          v-if="this.targetIndustry"
          class="newInput unselectableInput"
          style="width: 200px"
          v-model="this.targetIndustry"
          placeholder="select industry"
          readonly
        /> -->
        <select
          class="newSelect"
          :class="{
            // highlightSelect: this.targetIndustry == null,
            targetSelect:
              (this.warnings.industry && this.targetIndustry == null) ||
              !this.listOfIndustries.includes(this.targetIndustry),
          }"
          v-model="this.targetIndustry"
          style="width: 285px"
        >
          <option :value="null" disabled selected>select industry</option>
          <!-- <option
            v-for="ind in this.industryCipher"
            :key="ind.NUM"
            :value="ind.NUM"
          >
            {{ ind.NAME }}
          </option> -->
          <option v-for="ind in this.listOfIndustries" :key="ind" :value="ind">
            {{ ind }}
          </option>
          <option
            v-if="!this.listOfIndustries.includes(this.startingIndustry)"
            disabled
          >
            unsupported industry
          </option>
          <option v-if="!this.listOfIndustries.includes(this.startingIndustry)">
            {{ this.startingIndustry }}
          </option>
        </select>
      </div>
      <div style="margin: 0 5px 20px 0">
        <div class="greyTitle" style="margin: 0 0 5px 0">Country</div>
        <!-- <input
          v-if="this.targetLocation"
          class="newInput unselectableInput"
          style="width: 200px"
          v-model="this.targetLocation"
          placeholder="select industry"
          readonly
        /> -->
        <select
          class="newSelect"
          :class="{
            // highlightSelect: this.targetLocation == null,
            targetSelect:
              (this.warnings.location && this.targetLocation == null) ||
              !this.listOfCountries.includes(this.targetLocation),
          }"
          style="width: 285px"
          v-model="this.targetLocation"
        >
          <option value="" disabled selected>select country</option>
          <option
            v-for="country in this.listOfCountries"
            :key="country"
            :value="country"
          >
            {{ country }}
          </option>
          <option
            v-if="!this.listOfCountries.includes(this.startingLocation)"
            disabled
          >
            unsupported country
          </option>
          <option v-if="!this.listOfCountries.includes(this.startingLocation)">
            {{ this.startingLocation }}
          </option>
        </select>
      </div>
    </div>
  </div>
  <div
    style="margin-bottom: 10px; border-radius: 7px; border: thin solid white"
    :class="{
      targetSelect: this.warnings.founders && this.startupFounders.length == 0,
    }"
  >
    <div class="founderBoxTitle">
      <div>
        <div class="flexLeftRow" style="margin-bottom: 10px">
          <div
            class="greyTitle"
            style="margin: 0; display: flex; align-items: center"
          >
            <!-- <svg
            xmlns="http://www.w3.org/2000/svg"
            height="24"
            viewBox="0 -960 960 960"
            width="24"
            fill="grey"
          >
            <path
              d="M232-241.913h239v-14q0-18-9-32t-23-19q-32-11-50-14.5t-35-3.5q-19 0-40.5 4.5t-48.5 13.5q-15 5-24 19t-9 32v14Zm361-67h120q10.833 0 17.917-7.116 7.083-7.116 7.083-18t-7.083-17.884q-7.084-7-17.917-7H593q-10.833 0-17.917 7.116-7.083 7.117-7.083 18 0 10.884 7.083 17.884 7.084 7 17.917 7Zm-239-50q22.5 0 38.25-15.75t15.75-38.25q0-22.5-15.75-38.25T354-466.913q-22.5 0-38.25 15.75T300-412.913q0 22.5 15.75 38.25t38.25 15.75Zm239-63h120q10.833 0 17.917-7.116 7.083-7.116 7.083-18t-7.083-17.884q-7.084-7-17.917-7H593q-10.833 0-17.917 7.116-7.083 7.117-7.083 18 0 10.884 7.083 17.884 7.084 7 17.917 7ZM145.087-65.869q-32.507 0-55.862-23.356-23.356-23.355-23.356-55.862v-469.826q0-32.74 23.356-56.262 23.355-23.521 55.862-23.521h230.782v-129.826q0-29.652 19.913-49.913 19.913-20.261 50.955-20.261h67.374q30.063 0 50.324 20.261t20.261 49.913v129.826h230.217q32.74 0 56.262 23.521 23.521 23.522 23.521 56.262v469.826q0 32.507-23.521 55.862-23.522 23.356-56.262 23.356H145.087Zm0-79.218h669.826v-469.826H583.566v30q-2.261 29.131-23.37 44.565-21.109 15.435-46.085 15.435h-67.657q-25.541 0-46.933-15.717-21.391-15.718-23.087-44.283v-30H145.087v469.826ZM451.13-600.174h58.305v-219.261H451.13v219.261ZM480-380Z"
            />
          </svg> -->
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              color="grey"
              fill="none"
            >
              <path
                d="M14 9H18"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <path
                d="M14 12.5H17"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
              />
              <rect
                x="2"
                y="3"
                width="20"
                height="18"
                rx="5"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linejoin="round"
              />
              <path
                d="M5 16C6.20831 13.4189 10.7122 13.2491 12 16"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10.5 9C10.5 10.1046 9.60457 11 8.5 11C7.39543 11 6.5 10.1046 6.5 9C6.5 7.89543 7.39543 7 8.5 7C9.60457 7 10.5 7.89543 10.5 9Z"
                stroke="currentColor"
                stroke-width="1.5"
              />
            </svg>
            <span style="margin-left: 10px">Founders</span>
          </div>
        </div>
        <div>
          Please add and remove founders among decision makers we found:
        </div>
      </div>
      <div>
        <div class="greyTitle" style="margin: 0 0 10px 0">
          Manually add founder
        </div>
        <div class="flexRightRow">
          <input
            class="newInput"
            style="width: 200px"
            v-model="this.editingLink"
            placeholder="add founder linkedin"
            @keydown.enter="addFounderFromLinkedin(this.editingLink)"
          />
          <div
            style="margin-left: 5px"
            class="buttonBlue"
            @click="addFounderFromLinkedin(this.editingLink)"
          >
            Add
          </div>
        </div>
      </div>
    </div>
    <div class="showFounderList">
      <div
        v-for="(founderMaterial, index) of this.potentialFounders"
        :key="index"
      >
        <div
          class="showFounder"
          :class="
            this.startupFounders.includes(founderMaterial)
              ? 'selectedFounder'
              : ''
          "
          v-if="founderMaterial != 'LOADING'"
        >
          <div
            v-if="this.startupFounders.includes(founderMaterial)"
            @click="removeFounderFromSelected(founderMaterial)"
            class="selectFounderButtonExternal"
          >
            <div class="selectedFounderButtonInternal">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="18"
                viewBox="0 -960 960 960"
                width="18"
                fill="#721cff"
              >
                <path
                  d="m382-324.769 352.077-352.077q5.846-5.846 13.769-6.231 7.923-.385 14.539 6.231Q769-670.231 769-662.577t-6.615 14.269l-357.77 358q-9.692 9.693-22.615 9.693-12.923 0-22.615-9.693l-162-162q-5.847-5.846-6.116-13.884-.269-8.039 6.346-14.654 6.616-6.616 14.27-6.616t14.269 6.616L382-324.769Z"
                />
              </svg>
            </div>
          </div>
          <div
            v-if="!this.startupFounders.includes(founderMaterial)"
            @click="addFounderToSelected(founderMaterial)"
            class="selectFounderButtonExternal"
          >
            <div class="selectFounderButtonInternal"></div>
          </div>
          <div
            :style="[!founderMaterial.title ? { 'padding-top': '5px' } : '']"
          >
            <div
              class="clickableName"
              :style="[!founderMaterial.title ? { 'margin-bottom': 0 } : '']"
              @click="openFounderLinkedin(founderMaterial.linkedin)"
            >
              <span
                style="
                  max-width: 180px;
                  white-space: nowrap;
                  overflow: hidden;
                  text-overflow: ellipsis;
                "
                >{{ founderMaterial.name }}</span
              >
              <svg
                width="16"
                height="16"
                viewBox="0 0 800 800"
                fill="#721cff"
                xmlns="http://www.w3.org/2000/svg"
                style="margin-left: 7px"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M100 0C44.7715 0 0 44.7715 0 100V700C0 755.228 44.7715 800 100 800H700C755.228 800 800 755.228 800 700V100C800 44.7715 755.228 0 700 0H100ZM248.83 664.619H130.138V307.527H248.83V664.619ZM189.484 258.789H188.7C148.884 258.789 123.077 231.358 123.077 197.073C123.077 162.034 149.639 135.381 190.261 135.381C230.892 135.381 255.861 162.034 256.638 197.073C256.638 231.358 230.892 258.789 189.484 258.789ZM676.923 664.619H558.2V473.574C558.2 425.589 541.046 392.835 498.077 392.835C465.261 392.835 445.76 414.889 437.169 436.251C434.047 443.882 433.231 454.49 433.231 465.19V664.621H314.484C314.484 664.621 316.076 341.029 314.484 307.529H433.231V358.129C449.007 333.853 477.162 299.146 540.23 299.146C618.361 299.146 676.923 350.17 676.923 459.845V664.619Z"
                />
              </svg>
              <!-- <svg
                    xmlns="http://www.w3.org/2000/svg"
                    height="16"
                    viewBox="0 -960 960 960"
                    width="16"
                    style="transform: translate(0, 2px)"
                    class="clickFill"
                  >
                    <path
                      d="M212.309-140.001q-30.308 0-51.308-21t-21-51.308v-535.382q0-30.308 21-51.308t51.308-21h222.306q12.769 0 21.384 8.616 8.615 8.615 8.615 21.384t-8.615 21.384Q447.384-760 434.615-760H212.309q-4.616 0-8.463 3.846-3.846 3.847-3.846 8.463v535.382q0 4.616 3.846 8.463 3.847 3.846 8.463 3.846h535.382q4.616 0 8.463-3.846 3.846-3.847 3.846-8.463v-222.306q0-12.769 8.615-21.384t21.384-8.615q12.769 0 21.384 8.615 8.616 8.615 8.616 21.384v222.306q0 30.308-21 51.308t-51.308 21H212.309ZM760-717.847 409.846-367.693q-8.308 8.308-20.884 8.5-12.577.192-21.269-8.5-8.692-8.692-8.692-21.076 0-12.385 8.692-21.077L717.847-760H589.999q-12.769 0-21.384-8.615T560-789.999q0-12.769 8.615-21.384 8.615-8.616 21.384-8.616h193.846q15.461 0 25.807 10.347 10.347 10.346 10.347 25.807v193.846q0 12.769-8.616 21.384Q802.768-560 789.999-560t-21.384-8.615Q760-577.23 760-589.999v-127.848Z"
                    />
                  </svg> -->
            </div>
            <div class="founderTextBox" v-if="founderMaterial.title">
              {{ founderMaterial.title }}
            </div>
          </div>
        </div>
        <div class="showFounder" v-else>
          <div class="nohoverFounderButton">
            <div class="selectFounderButtonInternal"></div>
          </div>
          <div>
            <div
              class="loadingTextItem"
              style="width: 200px; margin-bottom: 5px"
            ></div>
            <div
              class="loadingTextItem"
              style="width: 220px; margin-bottom: 5px"
            ></div>
            <div class="loadingTextItem" style="width: 170px"></div>
          </div>
        </div>
      </div>
      <div
        v-if="this.potentialFounders.length == 0"
        class="greyTitle"
        style="margin: auto"
      >
        <span>no decision makers found</span>
      </div>
    </div>
  </div>
  <div class="greyTitle" style="margin: 0; margin-bottom: 10px">
    missing a founder? add them manually with a linkedin link
  </div>
  <div style="height: 200px"></div>
  <div class="bottomBar">
    <div class="flexLeftRow">
      <div
        v-if="
          this.targetStage != null &&
          this.targetIndustry != null &&
          this.targetLocation &&
          !this.confirmedEditPage &&
          this.foundersHaveLoaded &&
          this.startupFounders.length != 0
        "
        class="lunaCheckExt"
        :class="{ 'shake': this.warnings.confirm }"
        @click="confirmEditPage()"
      >
        <div class="lunaCheck"></div>
      </div>
      <div
        v-if="
          this.targetStage != null &&
          this.targetIndustry != null &&
          this.targetLocation &&
          this.confirmedEditPage &&
          this.foundersHaveLoaded &&
          this.startupFounders.length != 0
        "
        class="lunaCheckExt"
        @click="confirmEditPage()"
      >
        <div class="lunaChecked">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            height="18"
            viewBox="0 -960 960 960"
            width="18"
            fill="#721cff"
          >
            <path
              d="m382-324.769 352.077-352.077q5.846-5.846 13.769-6.231 7.923-.385 14.539 6.231Q769-670.231 769-662.577t-6.615 14.269l-357.77 358q-9.692 9.693-22.615 9.693-12.923 0-22.615-9.693l-162-162q-5.847-5.846-6.116-13.884-.269-8.039 6.346-14.654 6.616-6.616 14.27-6.616t14.269 6.616L382-324.769Z"
            />
          </svg>
        </div>
      </div>
      <div
        v-if="
          this.targetStage != null &&
          this.targetIndustry != null &&
          this.targetLocation &&
          this.foundersHaveLoaded &&
          this.startupFounders.length != 0
        "
        class="confirmationText"
        :class="{ 'shake': this.warnings.confirm }"
        style="font-size: 13px; padding-left: 10px; cursor: pointer"
        @click="confirmEditPage()"
      >
        I confirm that I've verified all information above
      </div>
      <div
        v-else-if="this.targetStage == null"
        style="font-size: 13px; padding-left: 10px"
      >
        Please select company stage
      </div>
      <div
        v-else-if="this.targetIndustry == null"
        style="font-size: 13px; padding-left: 10px"
      >
        Please select company industry
      </div>
      <div
        v-else-if="!this.targetLocation"
        style="font-size: 13px; padding-left: 10px"
      >
        Please select company location
      </div>
      <div
        v-else-if="!this.foundersHaveLoaded"
        style="font-size: 13px; padding-left: 10px"
      >
        Please wait until founders have loaded
      </div>
      <div
        v-else-if="this.startupFounders.length == 0"
        style="font-size: 13px; padding-left: 10px"
      >
        Please select or add at least one founder
      </div>
    </div>
    <div
      class="buttonBlue"
      style="width: fit-content"
      v-if="
        this.targetStage != null &&
        this.targetIndustry != null &&
        this.targetLocation &&
        this.confirmedEditPage &&
        this.foundersHaveLoaded &&
        this.startupFounders.length != 0
      "
      @click="runModel()"
    >
      Evaluate Startup
    </div>
    <div
      class="buttonBlue"
      style="
        width: fit-content;
        cursor: not-allowed;
        background-color: #721cff90;
      "
      v-else
      @click="warningMessage()"
    >
      Evaluate Startup
      <span class="hoverMessage"
        >Please confirm that you've verified all information</span
      >
    </div>
  </div>
</template>

<script>
// import firebase from "firebase";
// import db from "@/main.js";
// import { ref } from "vue";
import logAction from "@/trackers.js";
import axios from "axios";

export default {
  name: "EditCompany",
  props: {
    targetStartup: Object,
    stage: Object,
    industryCipher: Object,
    apiKey: String,
  },
  emits: {
    goToStage: null,
    runModel: null,
  },
  data() {
    return {
      editingLink: "",
      confirmedEditPage: false,
      // targetIndustry: this.targetStartup.industry[1]
      //   ? this.targetStartup.industry[1]
      //   : null,
      // targetIndustry: this.targetStartup.industry_simple[0]
      //   ? this.targetStartup.industry_simple[0]
      //   : null,
      // startingIndustry: this.targetStartup.industry_simple[0]
      //   ? this.targetStartup.industry_simple[0]
      //   : null,
      targetIndustry: this.targetStartup.industry_simple,
      startingIndustry: this.targetStartup.industry_simple,
      targetStage: this.targetStartup.stage ? this.targetStartup.stage : null,
      startingStage: this.targetStartup.stage ? this.targetStartup.stage : null,
      startingLocation: null,
      targetLocation: null,
      targetYear: this.targetStartup.year_founded,
      editFunding: this.targetStartup.funding,
      editRevenue: this.targetStartup.funding,
      // editRevenue: this.targetStartup.revenue,
      potentialFounders: [],
      startupFounders: [],
      canEditStage: false,
      foundersHaveLoaded: false,
      listOfCountries: ["US", "CA"],
      listOfIndustries: [
        "SaaS",
        "AI",
        "Crypto",
        "Fintech",
        "Cybersecurity",
        "Biotech",
        "HealthTech",
      ],
      listOfStages: ["Pre Seed", "Seed", "Series A"],
      warnings: {
        stage: false,
        year: false,
        industry: false,
        country: false,
        founders: false,
        confirm: false,
      },

      // fakeLoading: true,
      // fakeStep: 1,
      // fakeStepItem: "company",
    };
  },
  mounted() {
    this.openStartup();

    // setTimeout(() => {
    //   this.fakeStep = 2;
    //   this.fakeStepItem = "market";
    //   setTimeout(() => {
    //     this.fakeStep = 3;
    //     this.fakeStepItem = "founder";
    //     setTimeout(() => {
    //       this.fakeLoading = false;
    //       this.fakeStep = 1;
    //       this.fakeStepItem = "company";
    //     }, 5000);
    //   }, 5000);
    // }, 5000);
  },
  components: {},
  methods: {
    async openStartup() {
      console.log(this.targetStartup.funding);
      this.foundersHaveLoaded = false;
      // this.targetRevenue = this.targetStartup.revenue;
      this.targetRevenue = this.targetStartup.funding;
      this.targetFunding = this.targetStartup.funding.toString();
      if (
        this.targetStartup.location
        // && this.targetStartup.location[0].fullAddress.split(", ").slice(-1).pop()
      ) {
        // this.targetLocation = this.targetStartup.location[0].fullAddress
        //   .split(", ")
        //   .slice(-1)
        //   .pop();
        this.targetLocation = this.targetStartup.location;

        this.canEditLocation = true;

        // this.startingLocation = this.targetStartup.location[0].fullAddress
        //   .split(", ")
        //   .slice(-1)
        //   .pop();
        this.startingLocation = this.targetStartup.location;
      } else {
        this.targetLocation = "NA";
        this.startingLocation = "NA";
        this.canEditLocation = true;
      }

      if (this.targetStartup.linkedin_id == 88007673) {
        //perplexity
        this.targetFunding = "102300000";
        this.targetStage = "Series B";
      } else if (this.targetStartup.linkedin_id == 19107985) {
        //mercury
        this.targetFunding = "152214480";
        this.targetStage = "Series B";
      } else if (this.targetStartup.linkedin_id == 24024765) {
        //cohere
        this.targetFunding = "434946073";
        this.targetStage = "Series C";
      } else if (this.targetStartup.linkedin_id == 18922914) {
        //deel
        this.targetFunding = "679000000";
        this.targetStage = "Series D";
      } else if (this.targetStartup.linkedin_id == 93605691) {
        //moonarch
        this.targetFunding = "0";
        this.targetStage = "Pre Seed";
        this.targetYear = 2024;
      }

      this.startingStage = this.targetStage;

      this.editFunding = this.targetFunding
        .replace(/\D/g, "")
        .replace(/(\d)(?=(\d{3})+$)/g, "$1 ");

      // if (!this.listOfCountries.includes(this.targetLocation)) {
      //   this.listOfCountries.push(this.targetLocation);
      // }

      if (!this.targetStartup.stage) {
        this.canEditStage = true;
      }

      this.potentialFounders = ["LOADING"];
      this.startupFounders = [];
      // this.startupFounders = [];
      // this.potentialFounders.push({
      //   name: "Rasha Katabi",
      //   title: "CEO & Founder",
      //   linkedin: "https://www.linkedin.com/in/rasha-katabi-94b99015b",
      // });

      console.log(this.targetStartup);
      console.log(this.targetStartup.linkedin_url);

      const options = {
        method: "POST",
        url: "https://moonarchio--moonarch-api.modal.run/get-founders",
        headers: {
          "Authorization":
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoicG9ydGFsIiwic3Vic2NyaXB0aW9uX3RpZXIiOiJhZG1pbiIsImV4cGlyeV9kYXRlIjoiMjAyNC0xMi0wNFQwNDowMTozMC44ODcxOTUrMDA6MDAiLCJleHAiOjE3MzMyODQ4OTB9.Csj5xlUX05XClmWTwpWsYvPzgq8POOnqYtfgutXzytw",
        },
        data: {
          url: this.targetStartup.linkedin_url,
        },
      };
      try {
        const response = await axios.request(options);
        console.log("WORKED");

        console.log(response.data);
        for (let f of response.data.data) {
          this.potentialFounders.push(f);
        }
        // console.log(response.data);
        // return response.data.data;
      } catch (error) {
        console.log("NO WORK");
        // return error;
      }
      // this.dataForModel = {
      //   founder: [],
      //   company_name: this.targetStartup.name,
      //   year_founded: this.targetStartup.year_founded,
      //   month_founded: 0, //CHANGE THIS LATER
      //   industry: "SaaS",
      // };

      // const options = {
      //   method: "POST",
      //   url: "https://go-data-api.fly.dev/companies/scrape_employees",
      //   headers: {
      //     "Authorization": this.apiKey,
      //   },
      //   data: {
      //     linkedin_company_id: this.targetStartup.linkedin_id,
      //     linkedin_company_name: this.targetStartup.name,
      //   },
      // };
      // try {
      //   const response = await axios.request(options);
      //   this.startupFounders = [];
      //   this.potentialFounders = [];

      //   for (let f of response.data.employees) {
      //     let position = {
      //       companyUrn: "",
      //       title: "",
      //     };
      //     if (!f.currentPositions) {
      //       let poppedName = f.fullName.split(" - ");
      //       f.fullName = poppedName[0];
      //       position.companyUrn = this.targetStartup.linkedin_id;

      //       if (poppedName[1]) position.title = poppedName[1];
      //       // console.log(f);
      //       this.potentialFounders.push(f);
      //       // this.potentialFounders.push("LOADING");
      //       // await this.addFounderFromLinkedin(f.linkedinLink);
      //     } else {
      //       position = f.currentPositions[0];

      //       // for (let position of f.currentPositions) {
      //       var regex = /C[A-Z]O/;
      //       if (
      //         position.companyUrn == this.targetStartup.linkedin_id &&
      //         position.title.toLowerCase().includes("founder")
      //       ) {
      //         this.startupFounders.push(f);
      //         this.potentialFounders.push(f);
      //       } else if (
      //         !this.startupFounders.includes(f) &&
      //         position.companyUrn == this.targetStartup.linkedin_id &&
      //         (regex.test(position.title) ||
      //           (position.title.toLowerCase().includes("chief") &&
      //             position.title.toLowerCase().includes("officer")) ||
      //           (position.title.toLowerCase().includes("president") &&
      //             !position.title.toLowerCase().includes("vice")))
      //         // || position.title.toLowerCase().includes("board"))
      //       ) {
      //         this.potentialFounders.push(f);
      //       }
      //       // }
      //     }
      //   }
      // } catch (error) {
      //   console.error(error);
      // }
      this.foundersHaveLoaded = true;
      this.potentialFounders = this.potentialFounders.filter(
        (i) => i !== "LOADING"
      );
      this.warningMessage();

      if (this.targetStartup.linkedin_id == 93605691) {
        this.addFounderFromLinkedin(
          "https://www.linkedin.com/in/aidan-lochbihler-17a90a178/"
        );
        this.addFounderFromLinkedin(
          "https://www.linkedin.com/in/isaac-h-levy/"
        );
        this.addFounderFromLinkedin(
          "https://www.linkedin.com/in/inayat-irfan/"
        );
        this.addFounderFromLinkedin(
          "https://www.linkedin.com/in/victorien-garrigues/"
        );
      }
    },
    confirmEditPage() {
      this.confirmedEditPage = !this.confirmedEditPage;
    },
    openFounderLinkedin(link) {
      window.open(link);
      // if (link) {
      //   logAction(link, "review founder link");
      //   window.open(link);
      // } else if (urn) {
      //   logAction("https://linkedin.com/in/" + urn, "review founder link");
      //   window.open("https://linkedin.com/in/" + urn);
      // }
    },
    goBack() {
      logAction("search results", "go back on luna");
      this.$emit("goToStage", 1);
    },
    formatFundingInput(event) {
      this.targetFunding = event.target.value.replace(/\D/g, "");

      this.editFunding = this.targetFunding
        .replace(/\D/g, "")
        .replace(/(\d)(?=(\d{3})+$)/g, "$1 ");

      logAction(this.editFunding, "review funding to");
    },
    // formatRevenueInput(event) {
    //   this.targetRevenue = event.target.value.replace(/\D/g, "");

    //   this.editRevenue = this.targetRevenue
    //     .replace(/\D/g, "")
    //     .replace(/(\d)(?=(\d{3})+$)/g, "$1 ");

    //   logAction(this.editRevenue, "review revenue to");
    // },
    openLink(link) {
      if (!link.includes("https://")) {
        link = "https://" + link;
      }
      window.open(link, "_blank");
      logAction(link, "open link");
    },
    removeFounderFromSelected(founderProfile) {
      // founderMaterial.entityUrn,
      // founderMaterial.link
      if (founderProfile.link) {
        logAction(founderProfile.link, "remove founder from review");
      } else {
        logAction(
          "https://linkedin.com/in/" + founderProfile.entityUrn,
          "remove founder from review"
        );
      }
      this.startupFounders.splice(
        this.startupFounders.indexOf(founderProfile),
        1
      );
    },
    addFounderToSelected(founderProfile) {
      if (founderProfile.link) {
        logAction(founderProfile.link, "add founder to review");
      } else {
        logAction(
          "https://linkedin.com/in/" + founderProfile.entityUrn,
          "add founder to review"
        );
      }
      this.startupFounders.push(founderProfile);
    },
    async addFounderFromLinkedin(copyLink) {
      if (!copyLink.includes("linkedin.com/in/")) {
        return;
      }
      // console.log(copyLink);
      this.foundersHaveLoaded = false;
      // let copyLink = this.editingLink;
      this.editingLink = "";
      // this.potentialFounders.push("LOADING");
      this.potentialFounders.push("LOADING");

      let scrapedPerson = await this.scrapeFounderLinkedin(copyLink);

      if (
        scrapedPerson.name == "" &&
        scrapedPerson.linkedin == "" &&
        scrapedPerson.title == ""
      ) {
        this.potentialFounders.pop();
        this.foundersHaveLoaded = true;
        return;
      }
      // this.startupFounders.push(scrapedPerson);
      // this.potentialFounders[this.potentialFounders.indexOf("LOADING")] =
      //   scrapedPerson;

      // this.foundersForModel.push(scrapedPerson);
      // let currentPosTitle = "Founder";
      // if (this.startupFounders.length > 0) currentPosTitle = "Co-Founder";

      // let scrapedPersonDummy = {
      //   fullName: scrapedPerson.name,
      //   currentPositions: [
      //     {
      //       companyName: this.targetStartup.name,
      //       title: currentPosTitle,
      //     },
      //   ],
      //   geoRegion: "",
      //   link: scrapedPerson.links,
      // };
      this.potentialFounders[this.potentialFounders.indexOf("LOADING")] =
        scrapedPerson;
      this.startupFounders.push(scrapedPerson);

      // logAction(scrapedPerson.links, "add linkedin to review");

      this.foundersHaveLoaded = true;
      // this.potentialFounders = this.potentialFounders.filter(
      //   (i) => i !== "LOADING"
      // );
    },
    // async scrapeFounderLinkedin(link) {
    //   // console.log(link);
    //   this.isScrapingFounderLinkedin = true;
    //   const options = {
    //     method: "POST",
    //     url: "https://go-data-api.fly.dev/people/scrape",
    //     headers: {
    //       "Authorization": this.apiKey,
    //     },
    //     data: {
    //       linkedin_profile_url: link,
    //     },
    //   };
    //   // "https://www.linkedin.com/in/ACwAACCF1ZQBCIdvGkb-OnT3dAoML2UithB1Kf8"
    //   try {
    //     const response = await axios.request(options);

    //     this.isScrapingFounderLinkedin = false;
    //     // console.log(response.data.profile);

    //     return response.data.profile;
    //   } catch (error) {
    //     this.isError = true;
    //     let errorMessage = error;
    //     console.error(errorMessage);
    //     this.isScrapingFounderLinkedin = false;
    //     return "BROKEN";
    //   }
    // },
    async scrapeFounderLinkedin(link) {
      this.isScrapingFounderLinkedin = true;
      console.log(link);
      const options = {
        method: "POST",
        url: "https://moonarchio--moonarch-api.modal.run/scrape-person-linkedin",
        headers: {
          "Authorization":
            "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoicG9ydGFsIiwic3Vic2NyaXB0aW9uX3RpZXIiOiJhZG1pbiIsImV4cGlyeV9kYXRlIjoiMjAyNC0xMi0wNFQwNDowMTozMC44ODcxOTUrMDA6MDAiLCJleHAiOjE3MzMyODQ4OTB9.Csj5xlUX05XClmWTwpWsYvPzgq8POOnqYtfgutXzytw",
        },
        data: {
          url: link, //link,
        },
      };
      // "https://www.linkedin.com/in/ACwAACCF1ZQBCIdvGkb-OnT3dAoML2UithB1Kf8"
      try {
        const response = await axios.request(options);
        console.log("worked in edit company");
        this.isScrapingFounderLinkedin = false;

        console.log(response.data.data);
        return {
          "name": response.data.data.name,
          "linkedin": response.data.data.links,
          "link": response.data.data.links,
          "title": "founder",
        };
      } catch (error) {
        console.log("broken in edit company");
        this.isError = true;
        let errorMessage = error;
        console.error(errorMessage);
        this.isScrapingFounderLinkedin = false;
        return "BROKEN";
      }
    },
    warningMessage() {
      if (this.targetStage == null) {
        this.warnings.stage = false;
        setTimeout(() => {
          this.warnings.stage = true;
        }, 100);
      } else {
        this.warnings.stage = false;
      }

      if (this.targetIndustry == null) {
        this.warnings.industry = false;
        setTimeout(() => {
          this.warnings.industry = true;
        }, 100);
      } else {
        this.warnings.industry = false;
      }

      if (this.targetLocation == null) {
        this.warnings.location = false;
        setTimeout(() => {
          this.warnings.location = true;
        }, 100);
      } else {
        this.warnings.location = false;
      }

      if (this.startupFounders.length == 0) {
        this.warnings.founders = false;
        setTimeout(() => {
          this.warnings.founders = true;
        }, 100);
      } else {
        this.warnings.founders = false;
      }

      if (typeof this.targetYear != "number") {
        this.warnings.year = false;
        setTimeout(() => {
          this.warnings.year = true;
        }, 100);
      } else {
        this.warnings.year = false;
      }

      if (!this.confirmedEditPage) {
        this.warnings.confirm = true;
        setTimeout(() => {
          this.warnings.confirm = false;
        }, 1000);
      }
    },
    runModel() {
      logAction(this.targetStartup.linkedin_url, "run company through model");
      let editableData = {
        industry: this.targetIndustry,
        location: this.targetLocation,
        stage: this.targetStage,
        funding: this.editFunding,
        // revenue: this.editRevenue,
        revenue: this.targetRevenue,
        year: this.targetYear,
      };
      this.$emit("runModel", this.startupFounders, editableData);
    },
  },
  setup() {
    return {};
  },
};
</script>

<style scoped>
.fakeLoadingBox {
  position: fixed;
  top: 110px;
  bottom: 0;
  right: 0;
  left: 251px;
  z-index: 10;
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.circularLoading {
  margin-left: auto;
  margin-right: auto;
  animation: circular 3s infinite linear;
}
@keyframes circular {
  0% {
    transform: rotate(0deg) translateX(15px) rotate(0deg);
  }

  100% {
    transform: rotate(-360deg) translateX(15px) rotate(360deg);
  }
}
.bottomBar {
  width: 810px;
  /* bottom: 0; */
  /* position: absolute; */
  height: 60px;
  padding: 20px;
  margin: 20px 0 50px 0;
  border: thin solid #9595953f;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: white;
}
.lunaCheckExt {
  cursor: pointer;
  width: 25px;
  height: 25px;
  border-radius: 9px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.lunaCheckExt:hover {
  background: #731cff37;
}
.lunaCheck {
  border: thin solid #9595953f;
  width: 18px;
  height: 18px;
  border-radius: 7px;
}
.lunaCheckExt:hover .lunaCheck {
  border: thin solid #721cff;
}
.lunaChecked {
  background: #731cff72;
  border: #731cff thin solid;
  cursor: pointer;
  width: 18px;
  height: 18px;
  border-radius: 7px;
}
.founderTextBox {
  font-size: 14px;
  height: 45px;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 220px;
}
.showFounder {
  padding: 5px;
  border: solid thin #9595953f;
  background: white;
  border-radius: 5px;
  width: 261px;
  margin: 5px;
  display: flex;
}
.addFounder {
  padding: 5px;
  border: dashed thin #9595953f;
  background: #9595953f;
  border-radius: 5px;
  width: 300px;
  height: 120px;
  margin: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.addFounder:hover {
  cursor: pointer;
  border: dashed thin #721cff;
}
.nohoverFounderButton {
  border-radius: 50%;
  width: 27px;
  height: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}
.founderBoxTitle {
  /* width: calc(100% - 42px); */
  width: 850px;
  border: thin solid #9595953f;
  border-radius: 5px 5px 0 0;
  /* margin: 10px 10px 0 10px; */
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}
.showFounderList {
  display: flex;
  flex-wrap: wrap;
  background: #95959511;
  border: thin solid #9595953f;
  border-top: none;
  border-radius: 0 0 5px 5px;
  /* margin: 0 10px 10px 10px; */
  padding: 10px;
  width: 850px;
}
.selectedFounder {
  background: #731cff15;
}
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }
  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }
  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }
  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>

import { createApp } from 'vue';
import App from './App.vue';
// import firebase from 'firebase/app';
import { initializeApp } from '@firebase/app';
import { getAuth } from '@firebase/auth';
import { getFirestore } from '@firebase/firestore';
import { getStorage } from '@firebase/storage';
import { createRouter, createWebHistory } from 'vue-router';

// app router
import Login from './views/app/pages/Login.vue';
import Luna from './views/app/pages/Luna.vue';

// landing router
import Landing from './views/landing/Landing.vue';

// labs router
import Labs from './views/labs/Labs.vue';

// documentation router
import Documentation from './views/docs/Documentation.vue';
import DocAuth from './views/docs/DocAuth.vue';

var firebaseConfig = {
    apiKey: "AIzaSyDcP0Y8XwUTyMZA7YFOhPfmS7FyO8Phmys",
    authDomain: "moonarch-portal.firebaseapp.com",
    projectId: "moonarch-portal",
    storageBucket: "moonarch-portal.appspot.com",
    messagingSenderId: "810813954667",
    appId: "1:810813954667:web:b10cf75110e1f0a4d2ef2e",
    measurementId: "G-L90H3Q1ZBC"
};

const searchParams = new URLSearchParams(window.location.search);

const appRoutes = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Login',
      meta: { 
        requiresAuth: false,
        signup: searchParams.has("signup") && searchParams.get("signup")
        },
      component: Login,
    },
    {
      path: '/luna', //:subdomain/portal
      name: 'Luna',
      meta: { requiresAuth: true },
      component: Luna,
    },
  ],
});

const landingRoutes = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Moonarch',
      meta: { requiresAuth: false },
      component: Landing,
    },
  ],
});

const labsRoutes = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/',
      name: 'Labs',
      meta: { requiresAuth: false },
      component: Labs,
    },
  ],
});

const docsRoutes = createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: '/:doctype/:doc',
      name: 'Documentation',
      meta: { requiresAuth: true },
      component: Documentation,
    },
    {
      path: '/',
      name: 'DocAuth',
      meta: { requiresAuth: false },
      component: DocAuth,
    },
  ],
});

// Detect subdomain
const subdomain = window.location.hostname.split('.')[0];

// Choose routes based on subdomain
let routes = [];
if (subdomain === 'app') {
  routes = appRoutes;
} else if (subdomain === 'docs') {
  routes = docsRoutes;
} else if (subdomain === 'labs') {
  console.log("in labs");
  routes = labsRoutes;
} else {
  routes = landingRoutes;
}

routes.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && subdomain != "docs") {
    if (auth.currentUser) {
      next();
      // return;
    } else {
      next({ 
        name: 'Login',
        query: { redirect: to.fullPath, 
          signup: searchParams.has("signup") && searchParams.get("signup") == "true" ? true : false
        },
      });
      // return;
    }
  } else if (to.meta.requiresAuth && subdomain == "docs") {
    console.log("subdomain");
    if (!auth.currentUser) {
      console.log("is not user");
      next({ 
        name: 'DocAuth',
        // query: { redirect: to.fullPath },
      });
    } else {
      if (to.path == "/") {
        return next({ name: 'Documentation', params: { doctype: 'documentation', doc: 'overview' } });
      } else if (to.path.replace(/^\/|\/$/g, '') == "documentation" || to.path.replace(/^\/|\/$/g, '') == "endpoints") {
        return next({ name: 'Documentation', params: { doctype: to.path.replace(/^\/|\/$/g, ''), doc: 'overview' } });
      } else {
        next();
      }
    }
    // return;
  } else {
    console.log("went to next");
    next();
  }
});

const defaultTitle = 'Moonarch';
routes.afterEach((to) => {
  document.title = 'Moonarch | ' + to.name || defaultTitle;
});

// Initialize Firebase
const firebase_app = initializeApp(firebaseConfig);
const db = getFirestore(firebase_app);
const auth = getAuth(firebase_app);
const storage = getStorage(firebase_app);

const app = createApp(App);
app.use(routes);
app.mount('#app');

export {db, auth, storage };
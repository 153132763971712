<template>
  <NavBar :leftNav="[]" :rightNav="['Login to App']" />
</template>

<script>
// import firebase from "firebase";
// import db from "@/main.js";
import NavBar from "@/components/utilities/NavBar.vue";
// import { ref } from "vue";

export default {
  name: "Landing",
  components: {
    NavBar,
  },
  methods: {},
  setup() {
    return {};
  },
  data() {
    return {};
  },
};
</script>

<style></style>

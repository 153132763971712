<template>
  <div id="loginScreen">
    <div id="loginNav">
      <img height="25" src="@/assets/images/moonarch-logo.png" />
      <div
        class="loginlink"
        style="cursor: pointer"
        @click="openLink('https://calendly.com/inayatirfan/30min')"
      >
        Book Meeting
      </div>
    </div>
    <div v-if="!this.showSetPassword && !this.isSignup" id="loginBox">
      <form @submit.prevent="login">
        <div id="loginTitle">Log In</div>
        <div class="loginLabel">Email</div>
        <input
          class="newInput loginInput"
          v-model="email"
          type="text"
          id="email"
          name="email"
        />
        <div class="loginLabel">Password</div>
        <input
          class="newInput loginInput"
          v-model="password"
          type="password"
          id="password"
          name="password"
        />
        <div class="loginLabel" style="max-width: 294px; color: red">
          <svg
            v-if="this.errorMessage"
            style="margin-right: 7px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="17"
            height="17"
            color="red"
            fill="none"
          >
            <circle
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="1.5"
            />
            <path
              d="M11.992 15H12.001"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12 12L12 8"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {{ this.errorMessage }}
        </div>
        <input
          v-if="!loginLoading"
          class="buttonBlue loginButton"
          type="submit"
          value="Log In"
        />
        <div v-if="loginLoading" class="buttonBlue signupButtonLoading">
          <div class="turningAnimation">
            <svg width="20" height="20" viewBox="0 0 97 97" fill="none">
              <path
                d="M7 48.5C7 25.5802 25.5802 7 48.5 7"
                stroke="white"
                stroke-width="10"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <span style="margin-left: 10px">Logging in...</span>
        </div>
      </form>
    </div>
    <div v-if="!this.showSetPassword && this.isSignup" id="loginBox">
      <div id="loginTitle">Sign Up</div>
      <form @submit.prevent="signup">
        <div class="loginLabel">Email</div>
        <input
          class="newInput loginInput"
          v-model="email"
          type="text"
          id="email"
          name="email"
        />
        <div class="loginLabel" style="max-width: 294px; color: red">
          <svg
            v-if="this.errorMessage"
            style="margin-right: 7px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="17"
            height="17"
            color="red"
            fill="none"
          >
            <circle
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="1.5"
            />
            <path
              d="M11.992 15H12.001"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12 12L12 8"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {{ this.errorMessage }}
        </div>
        <input
          v-if="!signupLoading"
          class="buttonBlue loginButton"
          type="submit"
          value="Sign Up"
        />
        <div v-if="signupLoading" class="buttonBlue signupButtonLoading">
          <div class="turningAnimation">
            <svg width="20" height="20" viewBox="0 0 97 97" fill="none">
              <path
                d="M7 48.5C7 25.5802 25.5802 7 48.5 7"
                stroke="white"
                stroke-width="10"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <span style="margin-left: 10px">Signing up...</span>
        </div>
      </form>
    </div>
    <div v-if="this.showSetPassword" id="loginBox">
      <div id="loginTitle">Set Password</div>
      <form @submit.prevent="setPassword()">
        <div class="loginLabel">New Password</div>
        <input
          class="newInput loginInput"
          v-model="password1"
          type="password"
          id="password1"
          name="password1"
        />
        <div class="loginLabel">Repeat Password</div>
        <input
          class="newInput loginInput"
          v-model="password2"
          type="password"
          id="password2"
          name="password2"
        />
        <div class="loginLabel" style="max-width: 294px; color: red">
          <svg
            v-if="this.errorMessage"
            style="margin-right: 7px"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            width="17"
            height="17"
            color="red"
            fill="none"
          >
            <circle
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              stroke-width="1.5"
            />
            <path
              d="M11.992 15H12.001"
              stroke="currentColor"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12 12L12 8"
              stroke="currentColor"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          {{ this.errorMessage }}
        </div>
        <input
          v-if="!setPasswordLoading"
          class="buttonBlue loginButton"
          type="submit"
          value="Set Password"
        />
        <div v-if="setPasswordLoading" class="buttonBlue signupButtonLoading">
          <div class="turningAnimation">
            <svg width="20" height="20" viewBox="0 0 97 97" fill="none">
              <path
                d="M7 48.5C7 25.5802 25.5802 7 48.5 7"
                stroke="white"
                stroke-width="10"
                stroke-linecap="round"
              />
            </svg>
          </div>
          <span style="margin-left: 10px">Setting password...</span>
        </div>
      </form>
    </div>
    <div id="footer">
      <div id="loginBottomText">
        By using this portal you agree to our&nbsp;
        <span id="loginLink" @click="toggleTCPage()">Terms and Services</span>
        &nbsp;and have read our&nbsp;
        <span id="loginLink" @click="toggleDPage()">Disclaimer</span>
      </div>
    </div>
    <div
      v-if="this.toggleTerms || this.toggleDisclaimer"
      id="termsBack"
      @click="toggleOff()"
    ></div>
    <div v-if="this.toggleTerms" id="termsBox">
      <div id="leaveTerms" @click="toggleTCPage()">
        <div class="tcTitle">Moonarch Inc. Terms and Conditions</div>
        <div class="exitButton">
          <img src="@/assets/navIcons/close.png" height="15" width="15" />
        </div>
      </div>
      <TermsConditions />
    </div>
    <div v-if="this.toggleDisclaimer" id="termsBox">
      <div id="leaveTerms" @click="toggleTCPage()">
        <div class="tcTitle">Disclaimer</div>
        <div class="exitButton">
          <img src="@/assets/navIcons/close.png" height="15" width="15" />
        </div>
      </div>
      <Disclaimer />
    </div>
  </div>
</template>

<script>
// import { getAuth, signInWithEmailAndPassword } from "firebase/auth";
// import firebase from "firebase";
import {
  // onAuthStateChanged,
  setPersistence,
  signInWithEmailAndPassword,
  updatePassword,
  // getAuth,
  browserLocalPersistence,
} from "@firebase/auth";
import { db, auth } from "@/main.js";
import { getDoc, doc, updateDoc } from "@firebase/firestore";
import TermsConditions from "@/views/app/popupViews/login/TermsConditions.vue";
import Disclaimer from "@/views/app/popupViews/login/Disclaimer.vue";
import logAction from "@/trackers.js";

export default {
  name: "Login",
  props: {},
  data() {
    const searchParams = new URLSearchParams(window.location.search);
    // const db = getFirestore();
    return {
      // db: db,
      toggleTerms: false,
      toggleDisclaimer: false,
      showSetPassword: false,
      error: null,
      loggedInUser: null,
      isSignup:
        searchParams.has("signup") && searchParams.get("signup") == "true",
      loginLoading: false,
      signupLoading: false,
      setPasswordLoading: false,

      email: null,
      password: null,
      password1: null,
      password2: null,
      userId: "",
      currentPassword: "",
      errorMessage: "",
    };
  },
  components: {
    TermsConditions,
    Disclaimer,
  },
  // mounted() {
  // },
  methods: {
    // MAIN
    // * FUNCTION to toggle TC page
    toggleTCPage() {
      this.toggleTerms = !this.toggleTerms;
    },

    toggleDPage() {
      this.toggleDisclaimer = !this.toggleDisclaimer;
    },

    toggleOff() {
      this.toggleTerms = false;
      this.toggleDisclaimer = false;
    },

    openLink(url) {
      window.open(url, "_blank").focus();
    },

    // MAIN
    // * FUNCTION to sign up user
    signup() {
      try {
        // const auth = getAuth();
        this.email = this.email.replace(" ", "");
        if (this.email == null || this.email == "") {
          logAction(this.email, "failed signup attempt");
          this.errorMessage = "Please enter an email";
          return;
        }

        this.signupLoading = true;
        setPersistence(auth, browserLocalPersistence)
          .then(() => {
            signInWithEmailAndPassword(auth, this.email, this.email)
              .then(async () => {
                this.currentPassword = this.password;
                this.errorMessage = null;
                this.showSetPassword = true;
                logAction(this.email, "successful signup attempt");
                this.signupLoading = false;
              })
              .catch(() => {
                this.errorMessage = "Please use the correct email address";
                logAction(this.email, "failed signup attempt");
                this.signupLoading = false;
              });

            // setPersistence(auth, auth.Auth.Persistence.LOCAL).then(() => {
            //   auth
            //     .signInWithEmailAndPassword(this.email, this.email)
            //     .then(() => {
            //       this.currentPassword = this.password;
            //       this.errorMessage = null;
            //       this.showSetPassword = true;
            //       logAction(this.email, "successful signup attempt");
            //       this.signupLoading = false;
            //     })
            //     .catch(() => {
            //       this.errorMessage = "Please use the correct email address";
            //       logAction(this.email, "failed signup attempt");
            //       this.signupLoading = false;
            //     });
            // });
          })
          .catch(() => {
            this.errorMessage = "Please use the correct email address";
            logAction(this.email, "failed signup attempt");
            this.signupLoading = false;
          });
      } catch (error) {
        this.errorMessage = "Please use the correct email address";
        logAction(this.email, "failed signup attempt");
        this.signupLoading = false;
      }
    },

    // MAIN
    // * FUNCTION to login in user
    async login() {
      try {
        // const auth = getAuth();
        this.email = this.email.replace(" ", "");
        this.loginLoading = true;
        setPersistence(auth, browserLocalPersistence)
          .then(() => {
            signInWithEmailAndPassword(auth, this.email, this.password)
              .then(async () => {
                this.currentPassword = this.password;
                this.errorMessage = null;
                logAction(this.email, "successful login attempt");
                this.loginLoading = false;

                logAction(navigator.userAgent, "logged in from login.vue");

                if (auth.currentUser) {
                  try {
                    const user_ref = doc(db, "users", auth.currentUser.uid);

                    const docSnap = await getDoc(user_ref);

                    if (docSnap.exists()) {
                      this.userInfo = auth.currentUser;
                      if (docSnap.data().LUNA_FOCUS) {
                        this.isLuna = true;
                        // this.$router.push({
                        //   name: "Luna",
                        //   params: {
                        //     companyID: "",
                        //   },
                        // });
                      }
                    } else {
                      console.error("Document does not exist");
                      this.errorMessage = "Email or password is wrong";
                      logAction(this.email, "failed login attempt");
                      this.loginLoading = false;
                    }
                  } catch (error) {
                    console.error("Error fetching document:", error);
                    this.errorMessage =
                      "An error occurred while fetching user data";
                    logAction(this.email, "failed login attempt");
                    this.loginLoading = false;
                  }
                } else {
                  console.error("auth.currentUser is null");
                  this.errorMessage = "User is not authenticated";
                  this.loginLoading = false;
                }
              })
              .catch((error) => {
                console.error("Sign-in error:", error);
                this.errorMessage = "Email or password is wrong";
                logAction(this.email, "failed login attempt");
                this.loginLoading = false;
              });
          })
          .catch(() => {
            this.errorMessage = "Email or password is wrong";
            logAction(this.email, "failed login attempt");
            this.loginLoading = false;
          });
      } catch (error) {
        this.errorMessage = "Email or password is wrong";
        logAction(this.email, "failed login attempt");
        this.loginLoading = false;
      }
    },

    // MAIN
    // * FUNCTION to set password of new user (email sign up process)
    setPassword() {
      if (this.password1 == this.password2) {
        // const auth = getAuth();
        this.setPasswordLoading = true;
        var timestamp = new Date();
        updatePassword(auth.currentUser, this.password1)
          .then(() => {
            const setup_ref = doc(db, "users", auth.currentUser.uid);
            updateDoc(setup_ref, {
              CREATED: timestamp,
              SETUP: true,
            });
            // db.collection("users").doc(auth.currentUser.uid).update({
            //   CREATED: timestamp,
            //   SETUP: true,
            // });
            logAction(this.email, "successful set password attempt");
            this.setPasswordLoading = false;
            this.$router.push({
              name: "Luna",
            });
          })
          .catch(() => {
            this.errorMessage = "Something went wrong, please reload page";
            logAction(this.email, "failed set password attempt");
            this.setPasswordLoading = false;
          });

        // auth.currentUser
        //   .updatePassword(this.password1)
        //   .then(() => {
        //     onAuthStateChanged(auth, (user) => {
        //       db.collection("users").doc(user.uid).update({
        //         CREATED: timestamp,
        //         SETUP: true,
        //       });
        //     });
        //     logAction(this.email, "successful set password attempt");
        //     this.setPasswordLoading = false;
        //   })
        //   .catch(() => {
        //     this.errorMessage = "Something went wrong, please reload page";
        //     logAction(this.email, "failed set password attempt");
        //     this.setPasswordLoading = false;
        //   });
      } else {
        this.errorMessage = "Passwords do not match";
        logAction(this.email, "failed set password attempt");
      }
    },
  },
};
</script>

<style scoped>
#loginScreen {
  /* height: calc(100vh - 50px); */
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f9f4ff;
  flex-direction: column;
  position: absolute;
  z-index: 1;
}
#loginNav {
  top: 0;
  left: 0;
  right: 0;
  padding: 20px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
#footer {
  position: absolute;
  bottom: 0;
  width: 500px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.footerItem {
  font-family: sans-serif;
  color: black;
  font-size: 13px;
  text-decoration: none;
}
.footerLink:hover {
  cursor: pointer;
  text-decoration: underline;
}
#loginBox {
  border: thin #0000002e solid;
  box-shadow: 0 0px 8px 0 #0000001d;
  background: #fefefe;
  border-radius: 15px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  /* height: 260px; */
  height: fit-content;
  width: 350px;
  padding: 30px;
  /* align-items: center; */
  /* justify-content: space-between; */
}
.loginButton {
  margin-top: 15px;
  max-width: 210px;
  max-height: 35px;
  min-width: 210px;
  min-height: 35px;
}
.signupButtonLoading {
  margin-top: 15px;
  max-width: 168px;
  max-height: 33px;
  min-width: 168px;
  min-height: 33px;
}
#loginTitle {
  font-size: 20px;
  margin-bottom: 40px;
}
.loginInput {
  padding: 10px;
  width: 328px;
  margin-bottom: 10px;
}
.loginLabel {
  font-size: 12px;
  color: gray;
  /* margin-left: 15px; */
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}
/* .loginLabel {
  position: absolute;
  font-size: 12px;
  margin-top: -63px;
  margin-left: 15px;
  background-color: white;
  font-family: dm;
  color: #2f2f2f47;
  pointer-events: none;
  height: 15px;
}
.loginInput:focus ~ .loginLabel,
.loginInput:hover ~ .loginLabel {
  color: #721cff;
} */
#loginBottomText {
  margin-top: 5px;
  font-family: sans-serif;
  color: grey;
  font-size: 13px;
  display: flex;
}
.loginLink {
  color: #721cff;
  cursor: pointer;
}
.loginLink:hover {
  text-decoration: underline;
}
#loginLink {
  color: #721cff;
  cursor: pointer;
}
#loginLink:hover {
  text-decoration: underline;
}
#termsBack {
  display: flex;
  position: fixed;
  right: 0px;
  bottom: 0px;
  left: 0px;
  top: 0px;
  z-index: 3;
  background-color: #00000020;
  backdrop-filter: blur(10px);
}
#termsBox {
  display: flex;
  background: white;
  position: fixed;
  margin: auto;
  width: 900px;
  left: calc(50% - 450px);
  right: calc(50% - 450px);
  top: 95px;
  bottom: 50px;
  border-radius: 7px;
  /* bottom: 0px;
  left: 300px;
  top: 90px; */
  /* border-left: thin #2f2f2f47 solid; */
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-end;
  z-index: 4;
}
#leaveTerms {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.exitButton {
  border-radius: 7px;
  padding: 10px;
  cursor: pointer;
  margin-right: 10px;
}
.exitButton:hover {
  background: #fefefe;
}
.tcTitle {
  font-weight: bold;
  font-size: 20px;
  margin: 20px;
}
</style>
